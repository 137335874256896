import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { Link } from 'mui/components';


export function SelectSurvey({ practice = false }: { practice?: boolean }) {
  const urlBase = practice ? '/practice' : '/surveys';
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{alignItems: 'center', textAlign: 'center'}}>
      <Typography variant="h5">
        Choose entity type to {practice ? 'practice' : 'review'}
      </Typography>
      <Link to={`${urlBase}?survey_type=company`}>
        <Button variant="contained">Companies</Button>
      </Link>
    </Stack>
  );
}

export function NoMoreSurveys({ surveyType, practice }: { surveyType: string; practice: boolean; }) {
  const surveyTypeTitle = `${practice ? 'practice ': ''}${surveyType}`
  return (
    <Typography variant="h5">
      There are no more {surveyTypeTitle} surveys available.
    </Typography>
  )
}