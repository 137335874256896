import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import * as DocumentTitle from 'react-document-title';
import { MRT_ColumnDef } from 'material-react-table';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  PersonFindResponse,
  CompanySearchListResponse,
  PersonSourceListResponse,
  PersonSourceResponse
} from 'api/graphql';
import {
  ContainerViewport,
  ErrorBoundary,
  ErrorBox,
  ContentSection,
  Link,
  Sus,
  Table
} from 'mui/components';
import { useAPI, hookCacheKey } from 'hooks';

type SubmitData = {
}

function HalfWidthKeyPair({
  k,
  val,
  edit,
  width = 6,
  onChange
}: {
  k: string;
  val: any;
  edit?: boolean;
  width?: number;
  onChange?: (k: string, v: string) => void;
}){
  const isUrl = k.toLowerCase().endsWith('url') || k.toLowerCase().includes('website');

  return (
    <Grid item xs={width}>
      <Stack direction="row">
        <Typography sx={{fontWeight: 'bold'}}>{k}:</Typography>
        {!edit ? (
          <>
            {isUrl ? (
              <Link to={`https://${val}`}>
                {val}
              </Link>
            ) : (
              <Typography>&nbsp;{val || '{null}'}</Typography>
            )}
          </>
        ) : (
          <TextField
            defaultValue={val}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => onChange && onChange(k, e.target.value)}
          />
        )}
      </Stack>
    </Grid>
  )
}


function SourceSearchResultTable({
  firstName,
  lastName,
  source,
  matches,
  onMatchAdd,
  onMatchRemove
}: {
  firstName: string;
  lastName: string;
  source: string;
  matches: PersonSourceResponse[];
  onMatchAdd: (entityId: PersonSourceResponse) => void;
  onMatchRemove: (entityId: string) => void;
}){

  const { apiPersistence } = useAPI();

  const inputData = { firstName, lastName, source };
  const { data } = apiPersistence.usePersonSourceSearch(
    hookCacheKey('useGetNextCompanySurvey', inputData),
    {input: inputData}
  );

  const people = data?.personSourceSearch as PersonSourceListResponse;
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Source ID',
        accessorKey: 'entityId',
        width: 10
      },
      {
        header: 'Full Name',
        accessorKey: 'fullName',
        width: 10
      },
      {
        header: 'Other / Current Info',
        accessorKey: 'currentInfo',
        width: 10
      },
      {
        header: 'Add as match',
        Cell: ({ row }) => {
          const alreadyAdded = matches.filter((m) => m.entityId === row.original.entityId).length > 0;
          return (
            <Button
              variant="contained"
              color={alreadyAdded ? 'error' : 'primary'}
              onClick={() => {
                if (!alreadyAdded) {
                  onMatchAdd({...row.original});
                } else {
                  onMatchRemove(row.original.entityId);
                }
              }}
            >
          {alreadyAdded ? 'Remove' : 'Add'}
        </Button>)
        },
      },
    ],
    [matches]
  );
  return (
    <Table
      columns={columns}
      data={people.people}
      enableTopToolbar={false}
      perPage={100}
    />
  )
}



function AddNewMatchesModal({
  open,
  onClose,
  onMatchChange
}: {
  open: boolean;
  onClose: () => void;
  onMatchChange: (matches: PersonSourceResponse[]) => void;
}) {
  const [selectedType, setSelectedType] = React.useState<string | null>(null);
  const [firstNameVal, setFirstNameVal] = React.useState<string | null>(null);
  const [lastNameVal, setLastNameVal] = React.useState<string | null>(null);
  const [submitFirstName, setSubmitFirstName] = React.useState<string | null>(null);
  const [submitLastName, setSubmitLastName] = React.useState<string | null>(null);
  const [newMatches, setNewMatches] = React.useState<PersonSourceResponse[]>([]);

  const addMatch = (e: PersonSourceResponse) => {
    const currMatches = [...newMatches];
    currMatches.push({...e});
    setNewMatches(currMatches);
  }

  const removeMatch = (e: string) => {
    const removedMatches = [...newMatches].filter((m) => m.entityId !== e);
    setNewMatches([...removedMatches]);
  }

  const setSubmitNames = () => {
    setSubmitFirstName(firstNameVal);
    setSubmitLastName(lastNameVal);
  }

  useEffect(() => {
    onMatchChange(newMatches);
  }, [newMatches]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        py: 2,
        position:'absolute',
        top: '5%',
        left: '5%',
        overflow: 'auto',
        height: '90%',
        width: '90%',
        maxHeight: 800,
        display:'block'
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        <Stack direction="column" spacing={2} sx={{overflow: 'scroll'}}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2
            }}
          >
            <Typography variant="h6">Search for new matches</Typography>
            <FormControl fullWidth sx={{maxWidth: '300px'}}>
              <InputLabel sx={{background: 'white'}}>Data Source</InputLabel>
              <Select
                labelId="select-data-source"
                id="select-data-source"
                value={selectedType}
                label="Data Source"
                onChange={(e) => {
                  setSelectedType(e.target.value as string);
                }}
              >
                <MenuItem value="pitchbook">Pitchbook</MenuItem>
                <MenuItem value="revelio">Revelio</MenuItem>
                <MenuItem value="insciter">Insciter</MenuItem>
                <MenuItem value="affinity">Affinity</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            spacing={2}>
            <FormGroup sx={{width: '40%'}}>
              <TextField
                id="search-match-first"
                label="First Name"
                variant="outlined"
                onChange={(e) => {
                  setFirstNameVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSubmitNames()
                  }
                }}
              />
            </FormGroup>
            <FormGroup sx={{width: '40%'}}>
              <TextField
                id="search-match-last"
                label="Last Name"
                variant="outlined"
                onChange={(e) => {
                  setLastNameVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSubmitNames()
                  }
                }}
              />
            </FormGroup>
            <Button
              variant="contained"
              disabled={!firstNameVal || !selectedType}
              onClick={() => setSubmitNames()}
            >
              Search
            </Button>
          </Stack>
          <ErrorBoundary Fallback={ErrorBox}>
            <Sus>
              {submitFirstName && submitLastName && !!selectedType && (
                <SourceSearchResultTable
                  firstName={submitFirstName}
                  lastName={submitLastName}
                  source={selectedType}
                  matches={newMatches}
                  onMatchAdd={addMatch}
                  onMatchRemove={removeMatch}
                />
              )}
            </Sus>
          </ErrorBoundary>
        </Stack>
      </Box>
    </Modal>
  )
}

function PersonOverview({
  person,
  addNewBtn
}: {
  person: PersonFindResponse;
  addNewBtn: React.ReactNode;
}) {
  return (
    <>
      <Card sx={{p: 2}}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" sx={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Typography variant="h6">Person Overview</Typography>
              {addNewBtn}
            </Stack>
          </Grid>
          <HalfWidthKeyPair k="Name" val={person.fullName} />
          <HalfWidthKeyPair k="Linkedin URL" val={person.personLinkedinUrl}/>
          <HalfWidthKeyPair k="Email" val={person.email} />
          <HalfWidthKeyPair k="Primary Position" val={person.primaryPosition} />
          <HalfWidthKeyPair k="Phone" val={person.phone} />
          <HalfWidthKeyPair k="Bio" val={person.biography} width={12}/>
        </Grid>
      </Card>
    </>
  )
}

function PersonSourceAccordion({
  source,
  sourceString,
  sourceContent,
  entityId,
  onSave,
  onMatchRemoved,
  unmatches
}: {
  source: string;
  sourceString: string;
  entityId: string;
  sourceContent: string;
  onSave: (source: string, updateRcd: any) => void;
  onMatchRemoved: (source: string, matchId: string) => void;
  unmatches: Record<string, any>;
}){
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const originalSourceContentRcd = JSON.parse(sourceContent);
  const [sourceContentRcd, setSourceContentRcd] = useState({...originalSourceContentRcd});
  const isValid = Object.keys(sourceContentRcd).length > 0;

  const handleUserInput = (k: string, v: string) => {
    const updateRcd = {...sourceContentRcd};
    updateRcd[k] = v;
    setSourceContentRcd(updateRcd);
  }

  const propagateSourceChanges = () => {
    const updateRcd = {};
    Object.keys(sourceContentRcd).map((k) => {
      const origVal = originalSourceContentRcd[k];
      const newVal = sourceContentRcd[k];
      if (origVal !== newVal && !Array.isArray(origVal)){
        updateRcd[k] = newVal;
      }
    })
    onSave(source, updateRcd);
  }

  return (
    <Card sx={{p: 2, pt: 0}}>
      <Accordion
        disabled={!isValid || sourceString in unmatches}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
            <Typography>{source}</Typography>
        </AccordionSummary>
          <AccordionDetails>

            <Grid container>
              {Object.keys(sourceContentRcd).map((k) => (
                !['ROW_ID', 'BIOGRAPHY', 'BIO'].includes(k) && (
                  <HalfWidthKeyPair
                    edit={edit}
                    k={k}
                    val={sourceContentRcd[k]}
                    onChange={handleUserInput}
                  />
                ))
              )}
              {Object.keys(sourceContentRcd).map((k) => (
                !!['BIOGRAPHY', 'BIO'].includes(k) && (
                  <HalfWidthKeyPair
                    edit={edit}
                    k={k}
                    val={sourceContentRcd[k]}
                    width={12}
                    onChange={handleUserInput}
                  />
                ))
              )}
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button
                      variant="contained"
                      color={edit ? 'success' : 'primary'}
                      onClick={() => {
                        setEdit(!edit);
                        if (edit){
                          propagateSourceChanges()
                        }
                      }}
                  >
                    {edit ? 'Save' : 'Edit'}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setExpanded(false);
                      onMatchRemoved(sourceString, entityId)
                    }}
                  >
                    Remove Match
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
      </Accordion>
    </Card>
  )
}

function PersonEmploymentTable({
  recordString,
  relationship,
  onSave
}: {
  recordString: string;
  relationship: string;
  onSave: (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => void;
}){
  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Company',
        accessorKey: 'COMPANY_NAME',
        size: 50
      },
      {
        header: 'Role',
        accessorKey: 'ROLE',
        size: 100
      },
      {
        header: 'Description',
        accessorKey: 'DESCRIPTION',
        size: 180
      },
      {
        header: 'Start Date',
        accessorKey: 'START_DATE',
        size: 50
      },
      {
        header: 'End Date',
        accessorKey: 'END_DATE',
        size: 50
      },
    ],
    [recordString]
  );

  return <PersonRelationshipTable
    recordString={recordString}
    relationship={relationship}
    columns={columns}
    sortCol='START_DATE'
    onSave={onSave}
  />
}


function PersonEducationTable({
  recordString,
  relationship,
  onSave
}: {
  recordString: string;
  relationship: string;
  onSave: (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => void;
}){
  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Institute',
        accessorKey: 'INSTITUTE_NAME',
        size: 150
      },
      {
        header: 'Degree',
        accessorKey: 'DEGREE_RAW',
        size: 150
      },
      {
        header: 'Discipline',
        accessorKey: 'DEGREE_DISCIPLINES_RAW',
        size: 100
      },
      {
        header: 'Start Date',
        accessorKey: 'START_DATE',
        size: 70
      },
      {
        header: 'End Date',
        accessorKey: 'END_DATE',
        size: 70
      },
    ],
    [recordString]
  );

  return <PersonRelationshipTable
    recordString={recordString}
    relationship={relationship}
    columns={columns}
    sortCol='START_DATE'
    onSave={onSave}
  />
}


function PersonBoardPositionsTable({
  recordString,
  relationship,
  onSave
}: {
  recordString: string;
  relationship: string;
  onSave: (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => void;
}){
  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Company',
        accessorKey: 'COMPANY_NAME',
        size: 120
      },
      {
        header: 'Role',
        accessorKey: 'ROLE',
        size: 120
      },
      {
        header: 'Start Date',
        accessorKey: 'START_DATE',
        size: 70
      },
      {
        header: 'Verified (from PB)',
        Cell: ({ row }) => (row.original?.VERIFIED ? 'true' : 'false'),
        size: 70,
      }
    ],
    [recordString]
  );

  return <PersonRelationshipTable
    recordString={recordString}
    relationship={relationship}
    columns={columns}
    sortCol='START_DATE'
    onSave={onSave}
  />
}

function PersonAdvisoryPositionsTable({
  recordString,
  relationship,
  onSave
}: {
  recordString: string;
  relationship: string;
  onSave: (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => void;
}){
  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Company',
        accessorKey: 'COMPANY_NAME',
        size: 120
      },
      {
        header: 'Role',
        accessorKey: 'ROLE',
        size: 120
      },
      {
        header: 'Start Date',
        accessorKey: 'START_DATE',
        size: 70
      },
      {
        header: 'End Date',
        accessorKey: 'END_DATE',
        size: 70
      },
    ],
    [recordString]
  );

  return <PersonRelationshipTable
    recordString={recordString}
    relationship={relationship}
    columns={columns}
    sortCol='START_DATE'
    onSave={onSave}
  />
}

function PersonRelationshipTable({
  recordString,
  relationship,
  columns,
  sortCol,
  onSave
}: {
  recordString: string;
  relationship: string;
  columns: MRT_ColumnDef<any>[];
  sortCol: string;
  onSave: (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => void;
}){
  const [lastRcdId, setLastRcdId] = useState<number>(0);
  const [curraddedRecords, setCurraddedRecords] = useState<number[]>([]);
  const [addedValues, setAddedValues] = useState<Record<string, any>>({});
  const [saveRcdIds, setSaveRcdIds] = useState<number[]>([]);

  const records = JSON.parse(recordString).sort((a: any, b: any) => {
    return new Date(a[sortCol]).getTime() -
        new Date(b[sortCol]).getTime()
  }).reverse()
  const [saveRecords, setSaveRecords] = useState<Record<string, any>[]>(records);

  const columnsWithRemove = [
    ...columns,
    {
      header: 'Notes',
      accessorKey: 'notes'
    },
    {
      header: 'Remove',
      Cell: ({ row }) => {
        const RM_BUTTON = (
          <Button
            color="error"
            sx={{p: 0, maxWidth: '36px', minWidth: 'unset'}}
            onClick={() => {
              const existRcds = [...saveRecords].filter((rcd) => rcd.RCD_ID !== row.original.RCD_ID);
              const existNewRcds = [...curraddedRecords].filter((rcd_id) => rcd_id !== row.original.rcd_id);
              setSaveRecords(existRcds);
              setCurraddedRecords(existNewRcds);
            }}
          >
            X
          </Button>
        )
        if (saveRcdIds.includes(row.original.rcd_id)){
          return (
            <Stack direction="row" spacing={.5}>
              <CheckCircleOutlineIcon color="success"/>
              {RM_BUTTON}
            </Stack>
          )
        }
        return RM_BUTTON
      },
      size: 50,
    }
  ]

  const newRcdValid = (rcdId: number) => {
    const results = columns.map((obj, i) => {
      // User does not need to enter an end date
      if (obj.accessorKey === 'END_DATE'){
        return true;
      }
      const idVal = `${String(obj.accessorKey)}-${i}-${rcdId}`;
      const val = addedValues[idVal];
      return !!val
    })

    return results.every(x => !!x)
  }

  const getRemovedRcds = () => {
    // const origRecordIds = records.map(rcd => rcd.RCD_ID);
    const saveIds = [...saveRecords].map(rcd => rcd.RCD_ID)
    return [...records].filter(rcd => !saveIds.includes(rcd.RCD_ID))
  }

  const canSubmit = () => {
    const removed = getRemovedRcds()
    if (removed.length === 0 && curraddedRecords.length === 0){
      return false;
    }

    if (removed.length > 0 && curraddedRecords.length === 0){
      return true;
    }

    const canSubAdded = curraddedRecords.every((rcdId) => {
      const recordSub = newRcdValid(rcdId);
      return recordSub;
    })
    return canSubAdded;
  }

  const handleSave = () => {
    const newSaveRcdIds: number[] = [];
    const relationAdditions = curraddedRecords.map((rcdId) => {
      newSaveRcdIds.push(rcdId);
      const colsWithNotes = [...columns, {accessorKey: 'notes'}, {accessorKey: 'rcd_id'}];
      const rcdResult = {}
      colsWithNotes.forEach((obj, i) => {
        const idVal = `${String(obj.accessorKey)}-${i}-${rcdId}`;
        if (!!obj.accessorKey){
          rcdResult[obj.accessorKey] = addedValues[idVal]
        }
      })

      return rcdResult
    })
    setSaveRcdIds(newSaveRcdIds);
    onSave(relationship, relationAdditions, getRemovedRcds());
  }

  const getAddlRcd = (rcdId: number) => {
    const addNewRecord = {};
    const colsWithNotes = [...columns, {accessorKey: 'notes'}];
    colsWithNotes.map((obj, i) => {
      const idVal = `${String(obj.accessorKey)}-${i}-${rcdId}`;
      addNewRecord[obj.accessorKey || ''] = (
        <TextField
          key={idVal}
          id={idVal}
          variant="outlined"
          size="small"
          fullWidth
          value={addedValues[idVal] || ''}
          onChange={(e) => {
            const currAdded = {...addedValues}
            currAdded[idVal] = e.target.value;
            setAddedValues(currAdded);
          }}
        />
      )
    })
    addNewRecord['rcd_id'] = rcdId;
    return addNewRecord;
  }

  const generateAddedRecords = () => {
    return curraddedRecords.map((id) => {
      return getAddlRcd(id)
    })
  }

  return (
    <Card sx={{p: 2, pt: 0}}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{textTransform: 'capitalize'}}>{relationship}</Typography>
        </AccordionSummary>
          <AccordionDetails>
          <Table
            columns={columnsWithRemove}
            data={[...saveRecords, ...generateAddedRecords()]}
            enableTopToolbar={false}
            perPage={100}
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const existingAddl = [...curraddedRecords];
                existingAddl.push(lastRcdId);
                setCurraddedRecords(existingAddl);
                setLastRcdId(lastRcdId + 1)
              }}
            >
              Add new record
            </Button>
            <Button
                variant="contained"
                color="success"
                disabled={!canSubmit()}
                onClick={handleSave}
              >
                Save
              </Button>
          </Stack>
          </AccordionDetails>
      </Accordion>
    </Card>
  )
}

function NewMatchesCard({
  matches,
  onMatchRemove
}: {
  matches: PersonSourceResponse[];
  onMatchRemove: (entityId: string) => void;
}) {
  if (matches.length === 0) return null;

  const columns = React.useMemo<MRT_ColumnDef<PersonSourceResponse>[]>(
    () => [
      {
        header: 'Full Name',
        accessorKey: 'fullName',
      },
      {
        header: 'Source ID',
        accessorKey: 'entityId'
      },
      {
        header: 'Other / Current Info',
        accessorKey: 'currentInfo'
      },
      {
        header: 'Remove match',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              onMatchRemove(row.original.entityId);
            }}
          >
            Remove
          </Button>
        ),
      },
    ],
    [matches]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">New Matches</Typography>
        </Grid>
        <Table
          columns={columns}
          data={matches}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}


function UnmatchesCard({
  unmatches,
  onAddMatchBack
}: {
  unmatches: Record<string, string>;
  onAddMatchBack: (source: string) => void;
}) {
  if ( Object.keys(unmatches).length === 0) return null;

  const flatChanges: Record<string, string>[]= [];
  Object.keys(unmatches).map((source) => {
    const entityId = unmatches[source];
      flatChanges.push({
        source,
        entityId,
      })
  })

  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Entity ID',
        accessorKey: 'entityId',
        width: 10
      },
      {
        header: 'Add match back',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              onAddMatchBack(row.original.source);
            }}
          >
            Add back
          </Button>
        ),
      },
    ],
    [unmatches]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Removed Matches</Typography>
        </Grid>
        <Table
          columns={columns}
          data={flatChanges}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}



function SourceChangesCard({
  sourceChanges
}: {
  sourceChanges: Record<string, Record<string, any>>;
}) {
  if ( Object.keys(sourceChanges).length === 0) return null;

  const flatChanges: Record<string, any>[]= [];
  Object.keys(sourceChanges).map((source) => {
    const sourceChangesRcds = sourceChanges[source];
    Object.keys(sourceChangesRcds).map((k) => {
      flatChanges.push({
        source,
        field: k,
        value: sourceChangesRcds[k]
      })
    })
  })

  const columns = React.useMemo<MRT_ColumnDef<Record<string, any>>[]>(
    () => [
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Field',
        accessorKey: 'field',
      },
      {
        header: 'Value',
        accessorKey: 'value',
      }
    ],
    [flatChanges]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Source Changes Made</Typography>
        </Grid>
        <Table
          columns={columns}
          data={flatChanges}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}


function RelationAddedRemovedCard({
  addedRemoved,
  changes
}: {
  addedRemoved: string;
  changes: Record<string, Record<string, any>>;
}) {
  if ( Object.keys(changes).length === 0) return null;

  const flatChanges: Record<string, any>[]= [];
  Object.keys(changes).map((source) => {
    const sourceChangesRcds = changes[source];
    Object.keys(sourceChangesRcds).map((k) => {
      flatChanges.push({
        source,
        field: k,
        value: sourceChangesRcds[k]
      })
    })
  })

  const columns = React.useMemo<MRT_ColumnDef<Record<string, any>>[]>(
    () => [
      {
        header: 'Source',
        accessorKey: 'source',
        size: 50
      },
      {
        header: 'Field',
        accessorKey: 'field',
        size: 50
      },
      {
        header: 'Value',
        size: 250,
        Cell: ({ row }) => (
          <Stack direction="column" spacing={1}>
            <>
            {Object.keys(row.original.value).map((k) => {
              return (
              <Stack direction="row" spacing={2}>
                <strong>{k}</strong>:
                <Box sx={{pl: .5}}>
                  {row.original.value[k]}
                </Box>
              </Stack>
              )
            })}
            </>
          </Stack>
        ),
      }
    ],
    [flatChanges]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Relationships {addedRemoved}</Typography>
        </Grid>
        <Table
          columns={columns}
          data={flatChanges}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}

function SearchFoundPerson({ curiePersonId }: { curiePersonId: string }){

  const [completed, setCompleted] = useState(false);
  const { apiPersistence } = useAPI();
  const [searchForNewOpen, setSearchForNewOpen] = useState(false);
  const [newMatches, setNewMatches] = useState<PersonSourceResponse[]>([]);
  const [removedMatches, setRemovedMatches] = useState<Record<string, string>>({});
  const [sourceChanges, setSourceChanges] = useState<Record<string, Record<string, any>>>({});
  const [relationAdditions, setRelationAdditions] = useState<Record<string, Record<string, any>>>({});
  const [relationRemovals, setRelationRemovals] = useState<Record<string, Record<string, any>>>({});

  const inputData = { curiePersonId };

  const { data } = apiPersistence.usePersonFind(
    hookCacheKey('usePersonFind', inputData),
    {input: inputData}
  );

  const person = data?.personFind as PersonFindResponse;

  const generateFinalIds = () => {
    const rmIdSources = Object.keys(removedMatches);
    const affinityPersonId = rmIdSources.includes('affinity') ? null : person.affinityPersonId || null;
    const insciterPersonId = rmIdSources.includes('insciter') ? null : person.insciterPersonId || null;
    const pitchbookPersonId = rmIdSources.includes('pitchbook') ? null : person.pitchbookPersonId || null;
    const revelioPersonId = rmIdSources.includes('revelio') ? null : person.revelioPersonId || null;

    const newSourceMap: Record<string, any> = {}
    newMatches.forEach((newMatch) => {
      newSourceMap[newMatch.source] = newMatch.entityId
    })
    return {
      'affinityPersonId': newSourceMap?.affinity || affinityPersonId,
      'insciterPersonId': newSourceMap?.insciter || insciterPersonId,
      'pitchbookPersonId': newSourceMap?.pitchbook || pitchbookPersonId,
      'revelioPersonId': newSourceMap?.revelio || revelioPersonId
    }
  }

  const generateResult = () => {
    const removedArray: Record<string, string>[] = [];
    Object.keys(removedMatches).map((source) => {
      removedArray.push({
        source,
        entityId: removedMatches[source]
      })
    })
    const result: SubmitData = {
      original: person,
      newMatches: newMatches,
      removedMatches: removedArray,
      sourceChanges: sourceChanges,
      relationAdditions: relationAdditions,
      relationRemovals: relationRemovals,
      finalIds: generateFinalIds(),
      submittedTime: new Date().getTime()
    }
    return result
  }

  const onSubmit = async () => {
    const result = generateResult()
    document.body.style.cursor = 'wait';
    await apiPersistence.savePersonFix({
      input: {
        result: JSON.stringify(result)
      }
    });
    document.body.style.cursor = 'default';
    setCompleted(true)
  }

  useEffect(() => {
    setCompleted(false)
  }, [curiePersonId])

  if (completed){
    return <Navigate to="/search" replace />;
  }
  console.log('relationAdditions', relationAdditions)
  const removeMatch = (e: string) => {
    const removedMatches = [...newMatches].filter((m) => m.entityId !== e);
    setNewMatches([...removedMatches]);
  }

  const handleSourceChange = (source: string, updateRcd: any) => {
    const updateRcds = {...sourceChanges};
    updateRcds[source] = updateRcd;
    setSourceChanges(updateRcds);
  }

  const setMatchedRemoved = (source: string, matchId: string) => {
    const updatedRemovedMatches = {...removedMatches};
    updatedRemovedMatches[source] = matchId;
    setRemovedMatches({...updatedRemovedMatches});
  }

  const onPersonRelationAdded = (relationship: string, additions: Record<string, any>[]) => {
    const updatedRelAdditions = {...relationAdditions};
    updatedRelAdditions[relationship] = additions;
    setRelationAdditions({...updatedRelAdditions});
  }

  const onPersonRelationRemoved = (relationship: string, removals: Record<string, any>[]) => {
    const updatedRelRemovals = {...relationRemovals};
    updatedRelRemovals[relationship] = removals;
    setRelationRemovals({...updatedRelRemovals});
  }

  const onRelationSave = (relationship: string, additions: Record<string, any>[], removals: Record<string, any>[]) => {
    onPersonRelationAdded(relationship, additions);
    onPersonRelationRemoved(relationship, removals);
  }

  const hasChange = newMatches.length > 0
    || Object.keys(removedMatches).length > 0
    || Object.keys(sourceChanges).length > 0
    || Object.keys(relationAdditions).length > 0
    || Object.keys(relationRemovals).length > 0;

  return (
    <>
      <Stack direction="column" spacing={2}>
        <PersonOverview
          person={person}
          addNewBtn={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setSearchForNewOpen(true)}
              >
                Add new matches
              </Button>
              <Button
                variant="contained"
                color="success"
                disabled={!hasChange}
                onClick={onSubmit}
              >
                Save
              </Button>
            </Stack>
            }
        />
        <NewMatchesCard matches={newMatches} onMatchRemove={removeMatch} />
        <UnmatchesCard
          unmatches={removedMatches}
          onAddMatchBack={(source) => {
          const existingUnmatches = {...removedMatches}
          if (source in existingUnmatches){
            delete existingUnmatches[source];
            setRemovedMatches({...existingUnmatches});
          }
        }} />
        <SourceChangesCard sourceChanges={sourceChanges} />
        <RelationAddedRemovedCard addedRemoved='Added' changes={relationAdditions} />
        <RelationAddedRemovedCard addedRemoved='Removed' changes={relationRemovals} />
        <Card>
          <CardHeader title="Source Data" />
        </Card>
        <PersonSourceAccordion
          source="Pitchbook"
          sourceContent={person.pitchbook}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='pitchbook'
          entityId={person.pitchbookPersonId || ''}
          unmatches={removedMatches}
        />
        <PersonSourceAccordion
          source="Revelio"
          sourceContent={person.revelio}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='revelio'
          entityId={person.revelioPersonId || ''}
          unmatches={removedMatches}
        />
        <PersonSourceAccordion
          source="Insciter"
          sourceContent={person.insciter}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='insciter'
          entityId={person.insciterPersonId || ''}
          unmatches={removedMatches}
        />
        <PersonSourceAccordion
          source="Affinity"
          sourceContent={person.affinity}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='affinity'
          entityId={person.affinityPersonId || ''}
          unmatches={removedMatches}
        />
        <Card>
          <CardHeader title="Person Relationships" />
        </Card>
        <PersonEmploymentTable
          recordString={person.employment || '[]'}
          relationship='employment'
          onSave={onRelationSave}
        />
        <PersonEducationTable
          recordString={person.education || '[]'}
          relationship='education'
          onSave={onRelationSave}
        />
        <PersonBoardPositionsTable
          recordString={person.boardPositions || '[]'}
          relationship='board_positions'
          onSave={onRelationSave}
        />
        <PersonAdvisoryPositionsTable
          recordString={person.advisorPositions || '[]'}
          relationship='advisory_positions'
          onSave={onRelationSave}
        />
        <PersonAdvisoryPositionsTable
          recordString={person.sabPositions || '[]'}
          relationship='sab_positions'
          onSave={onRelationSave}
        />
      </Stack>
      <AddNewMatchesModal
        open={searchForNewOpen}
        onClose={() => setSearchForNewOpen(false)}
        onMatchChange={setNewMatches}
      />
    </>
  )
}

export function SearchPersonPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const curiePersonId = searchParams.get('curie_person_id');

  return (
    <DocumentTitle title="Page title">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection>
          <ErrorBoundary Fallback={ErrorBox}>
            <Sus>
              {!!curiePersonId  && (<SearchFoundPerson curiePersonId={curiePersonId} />)}
            </Sus>
          </ErrorBoundary>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
