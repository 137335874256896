import React, { createContext, useContext, useEffect, useState } from 'react';
import stringify from 'fast-json-stable-stringify';
import { GraphQLClient } from 'graphql-request';
import { GraphQlError, getSdkWithHooks, SdkWithHooks } from 'api/graphql';
import { getHttpSdk, SdkHttpAPI } from 'api/http';


import {
  BareFetcher,
  Key,
  Middleware,
  SWRConfig,
  SWRConfiguration,
  SWRHook,
  SWRResponse,
} from 'swr';
import { assertApiSuccess } from 'mui/model';
import { APP_CONFIG } from 'config/appConfig';

/**
 * Key-er fn for SWR - operation name + serialized payload.
 * to trigger query refreshes.
 **/
export function hookCacheKey(operationName: string, payload?: Object) {
  return `${operationName}|${stringify(payload || {})}`;
}

export const isErrorResponse = (
  response?: unknown
): response is GraphQlError => {
  return (
    (response as boolean) && (response as GraphQlError).error !== undefined
  );
};

export interface ApiSWRResponse<D, E> extends SWRResponse<D, E> {
  isLoading: boolean;
}

// Wrapper error type.
export interface ApiError extends Error {
  response?: any;
}

/**
 * Bounces API errors to error handling.
 **/
export const errorResponseMiddleware: Middleware = (useSWRnext: SWRHook) => <
  D,
  E
>(
  key: Key,
  fetcher: BareFetcher<D> | null,
  config: SWRConfiguration<D, E, BareFetcher<D>>
) => {
  const swr = useSWRnext(key, fetcher, config);

  if (swr.data) {
    const keys = Object.keys(swr.data) as Array<keyof typeof swr.data>;
    if (
      keys.length === 1 &&
      Object.prototype.hasOwnProperty.call(swr.data[keys[0]], '__typename')
    ) {
      assertApiSuccess(swr.data[keys[0]]);
    }
  }
  return swr;
};

/**
 * This is a middleware function that detects GraphQL api error responses
 * And bounces them on the error path, thus collapsing transport + application
 * errors.
 **/
export const errorMiddleware: Middleware = (useSWRnext: SWRHook) => <D, E>(
  key: Key,
  fetcher: BareFetcher<D> | null,
  config: SWRConfiguration<D, E, BareFetcher<D>>
): ApiSWRResponse<D, E> => {
  const [apiResponse, setApiResponse] = useState<D | undefined>(undefined);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const { error, data, isValidating, mutate } = useSWRnext(
    key,
    fetcher,
    config
  );

  useEffect(() => {
    if (error !== undefined) {
      // We have a GraphQL error (e.g. schema, transport etc)
      setApiResponse(undefined);
      setApiError((error as unknown) as ApiError);
      return;
    }

    if (data !== undefined) {
      const keys = Object.keys(data) as Array<keyof typeof data>;
      if (
        keys.length === 1 &&
        Object.prototype.hasOwnProperty.call(data[keys[0]], '__typename') &&
        isErrorResponse(data[keys[0]])
      ) {
        // The response is an ApplicationError
        // - e.g. validation, not found etc.
        const err = new Error('GraphQL API error') as ApiError;

        err.response = data[keys[0]];

        setApiResponse(undefined);
        setApiError(err);
        return;
      }

      setApiResponse(data);
      setApiError(undefined);
    }
  }, [data, error, config]);

  return {
    data: config.suspense ? data : apiResponse,
    // Not very legal, but super excellent.
    error: (apiError as unknown) as E,
    isLoading: !apiResponse && !apiError,
    isValidating,
    mutate,
  };
};

const APIContext = createContext({});

export interface APIRegistry {
  apiPersistence: SdkWithHooks;
  apiHttp: SdkHttpAPI;
}

export interface APIProviderProps {
  children: React.ReactNode;
}

export const useAPI = (): APIRegistry => {
  return useContext(APIContext) as APIRegistry;
};

export const APIProvider = (props: APIProviderProps) => {
  /**
   *  We can include a global SWR Config here if we need to tweak.
   *  Ref: https://swr.vercel.app/docs/global-configuration
   **/

  const graphqlClient = new GraphQLClient(
    `/api/internal/graphql`,
    {credentials: "include" }
  );
  const registry = {
    apiPersistence: getSdkWithHooks(graphqlClient),
    apiHttp: getHttpSdk()
  } as APIRegistry;

  return (
    // @ts-ignore
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <APIContext.Provider value={registry}>
        {props.children}
      </APIContext.Provider>
    </SWRConfig>
  );
};
