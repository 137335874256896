import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorPage } from 'mui/components';

export function NotFoundPage() {
  const location = useLocation();
  const image = '/static/mui/images/error-404.png';
  const formattedError = {
    ...new Error(),
    displayTitle: '404 - Page Not Found',
    displayMessage: `The page '${location.pathname}' is not one we are aware of!`,
  };
  return <ErrorPage image={image} formattedError={formattedError} />;
}
