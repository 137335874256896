import type {} from '@mui/lab/themeAugmentation';
import {
  Components,
  PaletteMode,
  PaletteOptions,
  Shadows,
  ThemeOptions,
  TypographyVariantsOptions,
} from '@mui/material';
import { alpha, createTheme, Theme } from '@mui/material/styles';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import React from 'react';

const customizeComponents = (): Components<Omit<Theme, 'components'>> => {
  return {
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '&:before': {
            content: 'none',
          },
          borderBottom: `solid ${theme.palette.divider}`,
          '&:last-of-type': {
            borderBottom: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        sx: { flexDirection: 'row-reverse' },
        expandIcon: <ExpandMoreRoundedIcon />,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          flexDirection: 'row-reverse',
          paddingLeft: theme.spacing(1.5),
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(-180deg)',
          },
          '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1.5),
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(1),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: '#4c0487',
          zIndex: theme.zIndex.appBar,
        }),
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '&:last-child': { paddingBottom: 0 },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:last-child': { paddingBottom: 0 },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h5' },
      },
      styleOverrides: {
        action: {
          margin: 0,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'md',
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          // @TODO - Remove !important after this is fixed: https://github.com/mui/material-ui/issues/27851
          paddingTop: `${theme.spacing(1)} !important`,
          paddingBottom: theme.spacing(1),
          gap: theme.spacing(3),
        }),
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        color: 'text.primary',
        variant: 'body1',
      },
    },
    MuiGrid: {
      defaultProps: {
        spacing: 1,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5),
        }),
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          // @TODO - Bootstrap consistency fix.
          ':hover': {
            color: 'inherit',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        disabled: ({ theme }) => ({
          color: theme.palette.text.disabled,
        }),
        selected: ({ theme }) => ({
          backgroundColor: alpha(theme.palette.primary.main, 0.8),
        }),
      },
    },
    MuiOutlinedInput: {
      // Required for correct focus behavior
      // on shrunk labels.
      defaultProps: {
        notched: true,
      },
      styleOverrides: {
        // @TODO - Bootstrap consistency fix.
        input: {
          '&.MuiOutlinedInput-input': {
            color: 'inherit !important',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          top: 96,
          [theme.breakpoints.up('sm')]: {
            maxWidth: 520,
            top: 96,
          },
        }),
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: ({ theme }) => ({
          paddingRight: theme.spacing(2.5),
        }),
        action: ({ theme }) => ({
          paddingRight: theme.spacing(2.5),
          '> .MuiIconButton-root': {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
          },
        }),
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          width: '100%',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTypography: {
      styleOverrides: {
        overline: {
          lineHeight: 1.33,
        },
        subtitle1: {
          lineHeight: 1.5,
        },
        subtitle2: {
          lineHeight: 1.43,
        },
      },
    },
  };
};

const customizeColors = (mode: PaletteMode): PaletteOptions => {
  return mode === 'light'
    ? {
        mode,
        primary: {
          contrastText: '#FFFFFF',
          main: '#0078F9',
          dark: '#0059B2',
          light: '#3393FA',
        },
        secondary: {
          contrastText: '#FFFFFF',
          main: '#003064',
          dark: '#000739',
          light: '#3E5992',
        },
        info: {
          contrastText: '#FFFFFF',
          main: '#0078F9',
          dark: '#0059B2',
          light: '#3393FA',
        },
        background: {
          default: '#FBFDFF',
        },
      }
    : {
        mode,
        primary: {
          contrastText: 'rgba(0, 0, 0, 0.87)',
          main: '#499ffb',
          dark: '#3393FA',
          light: '#6db2fb',
        },
        secondary: {
          contrastText: 'rgba(0, 0, 0, 0.87)',
          main: '#DBECFE',
          dark: '#A9BACB',
          light: '#FFFFFF',
        },
        info: {
          main: '#499ffb',
          dark: '#3393FA',
          light: '#6db2fb',
        },
      };
};

const customizeShadows = (): Shadows => {
  return [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
  ];
};

export const buildTheme = (mode: PaletteMode): ThemeOptions => {
  // Split off if heavier customization required.
  const typography: TypographyVariantsOptions = {
    // MUI's font sizes all convert px to rem,
    // the default assumed rem is 16, we currently
    // have bootstrap setting it to 10, this setting
    // allows MUI to be "aware" of what the explicit value is.
    htmlFontSize: 10,
    h6: {
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'capitalize',
    },
  };

  return {
    palette: customizeColors(mode),
    components: customizeComponents(),
    shadows: customizeShadows(),
    typography,
  };
};

export const lightTheme = createTheme(buildTheme('light'));
