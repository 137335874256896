import {
  ApplicationError,
  InputError,
  NotFoundError,
  SuccessResponse,
  ValidationError,
} from 'api/graphql';

export type ErrorResponse =
  | ApplicationError
  | InputError
  | NotFoundError
  | ValidationError;

export interface ApiError extends Error {
  response: ErrorResponse;
}

export interface FormattedError extends Error {
  displayTitle: string;
  displayMessage: string;
  retry?: () => void;
}

interface GraphQLResponse {
  __typename: string;
}

/**
 * Typeguard to verify i a GQL response is a known error type.
 * - matches presentation/graphql/error.py
 **/
export function isApiErrorResponse(r: unknown): r is ErrorResponse {
  return (
    (r as boolean) &&
    [
      'AlreadyExistsError',
      'ApplicationError',
      'InputError',
      'NotFoundError',
      'ReadonlyError',
      'ValidationError',
    ].includes((r as GraphQLResponse).__typename)
  );
}

export function isApiError(error: Error): error is ApiError {
  return 'response' in error;
}

export function isFormattedError(error: Error): error is FormattedError {
  return 'displayTitle' in error && 'displayMessage' in error;
}

export function isNotFoundError(r: ErrorResponse): r is NotFoundError {
  return r.__typename === 'NotFoundError';
}

/**
 * Transform an API error response to a Javascript typed error.
 **/
export function coerceErrorResponse(r: ErrorResponse): ApiError {
  return {
    ...new Error('GraphQL Error'),
    response: r,
  } as ApiError;
}

/**
 * Assertion function for successful response - usually for mutations.
 **/
export function assertApiSuccess(r: unknown): asserts r is SuccessResponse {
  if (isApiErrorResponse(r)) {
    throw coerceErrorResponse(r as ErrorResponse);
  }
}

export function formatError(err: Error): FormattedError {
  if (isApiError(err)) {
    // @TODO - Add handling for the rest of potential API error types
    if (isNotFoundError(err.response)) {
      return {
        ...err,
        displayTitle: 'Record Not Found',
        displayMessage: err.response.message,
      };
    }
  } else if (isFormattedError(err)) {
    return err;
  }
  return {
    ...err,
    displayTitle: 'Something went wrong',
    displayMessage: 'There was a problem trying to load this page.',
  };
}
