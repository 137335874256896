import React, {
  createContext,
  forwardRef,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Alert,
  AlertProps,
  ButtonProps,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

export enum SnackDuration {
  DEFAULT = 3000,
  ERROR = 6000,
}

export interface SnackMessage {
  // Snackbar's children
  // have an awkward typing that we mirror for the message.
  message?: React.ReactElement<any, any> | string;
  action?: React.ReactNode;
  duration?: SnackDuration;
}

const SnackbarContext = createContext({});

function SnackbarTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

export const useSnackbar = () => {
  return useContext(SnackbarContext) as {
    clearSnack: () => void;
    setAlertSuccess: (message: string) => void;
    setSnack: React.Dispatch<React.SetStateAction<SnackMessage>>;
  };
};

export const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
  ({ children, sx, ...rest }, ref) => {
    return (
      <Alert
        data-test="snackbar-alert"
        ref={ref}
        variant="filled"
        sx={[{ width: '100%' }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...rest}
      >
        {children}
      </Alert>
    );
  }
);

SnackbarAlert.displayName = 'SnackbarAlert';

export function SnackbarClose({ sx, ...rest }: ButtonProps) {
  return (
    <IconButton
      data-test="snackbar-close"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
      size="small"
      {...rest}
    >
      <CloseIcon sx={{ fontSize: 20, color: 'white' }} />
    </IconButton>
  );
}

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snack, setSnack] = useState<SnackMessage>({});
  const clearSnack = useCallback(() => setSnack({}), [setSnack]);
  const setAlertSuccess = useCallback(
    (message: string) =>
      setSnack({
        message: (
          <SnackbarAlert onClose={clearSnack} severity="success">
            {message}
          </SnackbarAlert>
        ),
      }),
    [clearSnack, setSnack]
  );
  const previous = useRef<SnackMessage | null>(null);

  useEffect(() => {
    if (snack) {
      previous.current = snack;
    }
  }, [snack, previous]);

  const customMessage = snack.message ?? previous.current?.message;
  const customAction = snack.action ?? previous.current?.action;
  const isMessageComponent = isValidElement(customMessage);

  return (
    <SnackbarContext.Provider value={{ setSnack, clearSnack, setAlertSuccess }}>
      {children}
      <Snackbar
        data-test="snackbar"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={snack.duration ?? SnackDuration.DEFAULT}
        onClose={clearSnack}
        open={snack.message !== undefined}
        TransitionComponent={SnackbarTransition}
        // Content props
        message={isMessageComponent ? undefined : customMessage}
        action={
          isMessageComponent ? (
            customAction
          ) : (
            <>
              {customAction}
              <SnackbarClose onClick={clearSnack} />
            </>
          )
        }
      >
        {isValidElement(customMessage) ? customMessage : undefined}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
