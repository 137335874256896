import React, { useEffect } from 'react';

import { CompanyReview, NoMoreSurveys, SubmitData } from 'mui/components';
import { CompanySurveyResponse } from 'api/graphql';
import { useAPI } from 'hooks';



export function CompanySurveyReal() {
  const { apiPersistence } = useAPI();
  const { data: survey, mutate } = apiPersistence.useGetNextCompanySurvey(
    'useGetNextCompanySurvey',
    {input: {practice: false}}
  );

  const companySurvey = (survey?.getNextCompanySurvey) as CompanySurveyResponse;

  const onSubmit = async (result: SubmitData) => {
    document.body.style.cursor = 'wait';
    result['match_id'] = companySurvey.matchId;
    result['company_a_original'] = companySurvey.companyA;
    result['company_b_original'] = companySurvey.companyB;
    result['practice'] = false;

    await apiPersistence.saveSurveyResult({
      input: {
        surveyType: "company",
        surveyResponse: JSON.stringify(result)
      }
    });
    mutate();
  }

  useEffect(() => {
    document.body.style.cursor = 'default';
  }, [survey])

  if (survey?.getNextCompanySurvey.__typename === 'NoMoreSurveysOfThisTypeResponse') {
    return <NoMoreSurveys surveyType="company" practice={false} />
  }

  return <CompanyReview companySurvey={companySurvey} onSubmit={onSubmit} practice={false}  />
}


export function CompanySurveyPractice() {
  const { apiPersistence } = useAPI();
  const { data: survey, mutate } = apiPersistence.useGetNextCompanySurvey(
    'useGetNextCompanySurvey',
    {input: {practice: true}}
  );


  const companySurvey = (survey?.getNextCompanySurvey) as CompanySurveyResponse;

  const onSubmit = async (result: SubmitData) => {
    document.body.style.cursor = 'wait';
    result['match_id'] = companySurvey.matchId;
    result['company_a_original'] = companySurvey.companyA;
    result['company_b_original'] = companySurvey.companyB;
    result['practice'] = true;

    await apiPersistence.saveSurveyResult({
      input: {
        surveyType: "company",
        surveyResponse: JSON.stringify(result)
      }
    });
    mutate();
  }

  useEffect(() => {
    document.body.style.cursor = 'default';
  }, [survey])

  if (survey?.getNextCompanySurvey.__typename === 'NoMoreSurveysOfThisTypeResponse') {
    return <NoMoreSurveys surveyType="company" practice={true} />
  }

  return <CompanyReview companySurvey={companySurvey} onSubmit={onSubmit} practice={true} />
}


export function CompanySurvey({ practice }: { practice: boolean }) {
  if (practice) {
    return <CompanySurveyPractice />
  }
  return <CompanySurveyReal />
}
