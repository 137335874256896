import React from 'react';
import * as DocumentTitle from 'react-document-title';
import { Grid, Typography } from '@mui/material';
import { ContainerViewport, ContentSection, SelectSurvey } from 'mui/components';

import { useAPI } from 'hooks';

export function HomePage() {

  const { apiHttp } = useAPI();
  const { data: currentUser } = apiHttp.useAuthUser()

  return (
    <DocumentTitle title="Home">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection sx={{justifyContent: 'center'}}>
          <Grid container>
          <Grid item xs={9}>
            <Typography variant="body2" sx={{pr: 2}}>
              Welcome to our data validation tool! Your contribution will greatly assist us in making
              informed investment decisions. We kindly request your assistance in meticulously
              reviewing and validating the data presented to you.
              Here's what you need to do:
              <br/>
              <ol>
                <li><strong>Task Overview:</strong></li>
                <ul>
                  <li>You will be shown profiles of various companies.</li>
                  <li>Your objective is to evaluate the accuracy and coverage of the data associated with each company.</li>
                  <li>Additionally, you will be asked to determine whether two companies are the same or not.</li>
                </ul>
                <li><strong>Thorough Examination:</strong></li>
                <ul>
                  <li>To ensure accurate validation, please click on provided links, explore websites, LinkedIn pages, and conduct searches on Google.</li>
                  <li>Be resourceful in gathering information, just as you would when solving the problem independently.</li>
                  <li>Do not limit yourself to the information presented directly to you.</li>
                </ul>
                <li><strong>Comprehensive Approach:</strong></li>
                <ul>
                  <li>Remember to critically assess the data and exercise attention to detail.</li>
                  <li>If you encounter insufficient information, indicating such is an acceptable response.</li>
                  <li>Your goal is to provide accurate and reliable validation.</li>
                </ul>
                <li><strong>Case Study Test:</strong></li>
                <ul>
                  <li>The first exercise you see today will consist of validating a set of 75 companies. This will take approximately 2 hours to complete.</li>
                  <li>This allows us to evaluate your attention to detail that we can rely on over an extended period.</li>
                </ul>
                <li><strong>Compensation and Work Hours:</strong></li>
                <ul>
                  <li>If you successfully pass the initial exercise, we will compensate you on a per record basis.</li>
                  <li>You will have the freedom to work as many hours as you desire.</li>
                  <li>The number of records reviewed, mistakes identified, and precision rates will be considered for intermittent spot bonuses.</li>
                </ul>
              </ol>
              Thank you for participating in our data validation initiative.
              Your dedication and meticulousness will play a significant role in improving our decision-making processes.
              If you have any questions or concerns, feel free to reach out to us. Happy validating!
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ borderLeft: 1 }}>
            <SelectSurvey practice={!currentUser?.is_active} />
          </Grid>
          </Grid>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
