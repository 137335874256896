import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom'
import * as DocumentTitle from 'react-document-title';
import { MRT_ColumnDef } from 'material-react-table';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import {
  CompanyFindResponse,
  CompanySearchListResponse,
  CompanySourceListResponse,
  CompanySourceResponse
} from 'api/graphql';
import {
  ContainerViewport,
  ErrorBoundary,
  ErrorBox,
  ContentSection,
  Link,
  Sus,
  Table
} from 'mui/components';
import { useAPI, hookCacheKey } from 'hooks';

type SubmitData = {
}

function HalfWidthKeyPair({
  k,
  val,
  edit,
  onChange
}: {
  k: string;
  val: any;
  edit?: boolean;
  onChange?: (k: string, v: string) => void;
}){
  const isUrl = k.toLowerCase().endsWith('url') || k.toLowerCase().includes('website');

  return (
    <Grid item xs={6}>
      <Stack direction="row">
        <Typography sx={{fontWeight: 'bold'}}>{k}:</Typography>
        {!edit ? (
          <>
            {isUrl ? (
              <Link to={`https://${val}`}>
                {val}
              </Link>
            ) : (
              <Typography>&nbsp;{val || '{null}'}</Typography>
            )}
          </>
        ) : (
          <TextField
            defaultValue={val}
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => onChange && onChange(k, e.target.value)}
          />
        )}
      </Stack>
    </Grid>
  )
}


function SourceSearchResultTable({
  companyName,
  source,
  matches,
  onMatchAdd,
  onMatchRemove
}: {
  companyName: string;
  source: string;
  matches: CompanySourceResponse[];
  onMatchAdd: (entityId: CompanySourceResponse) => void;
  onMatchRemove: (entityId: string) => void;
}){

  const { apiPersistence } = useAPI();

  const inputData = { companyName, source };
  const { data } = apiPersistence.useCompanySourceSearch(
    hookCacheKey('useGetNextCompanySurvey', inputData),
    {input: inputData}
  );

  const companies = data?.companySourceSearch as CompanySourceListResponse;
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Company Name',
        accessorKey: 'companyName',
      },
      {
        header: 'Source ID',
        accessorKey: 'entityId',
        width: 10
      },
      {
        header: 'Website',
        Cell: ({ row }) => (
          <Link
            to={`https://${row.original.website}`}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {row.original.website}
          </Link>
        ),
      },
      {
        header: 'LinkedIn',
        Cell: ({ row }) => (
          <Link
            to={`https://${row.original.companyLinkedinUrl}`}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {row.original.companyLinkedinUrl}
          </Link>
        ),
      },
      {
        header: 'Add as match',
        Cell: ({ row }) => {
          const alreadyAdded = matches.filter((m) => m.entityId === row.original.entityId).length > 0;
          return (
            <Button
              variant="contained"
              color={alreadyAdded ? 'error' : 'primary'}
              onClick={() => {
                if (!alreadyAdded) {
                  onMatchAdd({...row.original});
                } else {
                  onMatchRemove(row.original.entityId);
                }
              }}
            >
          {alreadyAdded ? 'Remove' : 'Add'}
        </Button>)
        },
      },
    ],
    [matches]
  );
  return (
    <Table
      columns={columns}
      data={companies.companies}
      enableTopToolbar={false}
      perPage={100}
    />
  )
}



function AddNewMatchesModal({
  open,
  onClose,
  onMatchChange
}: {
  open: boolean;
  onClose: () => void;
  onMatchChange: (matches: CompanySourceResponse[]) => void;
}) {
  const [selectedType, setSelectedType] = React.useState<string | null>(null);
  const [searchVal, setSearchVal] = React.useState<string | null>(null);
  const [submitName, setSubmitName] = React.useState<string | null>(null);
  const [newMatches, setNewMatches] = React.useState<CompanySourceResponse[]>([]);

  const addMatch = (e: CompanySourceResponse) => {
    const currMatches = [...newMatches];
    currMatches.push({...e});
    setNewMatches(currMatches);
  }

  const removeMatch = (e: string) => {
    const removedMatches = [...newMatches].filter((m) => m.entityId !== e);
    setNewMatches([...removedMatches]);
  }

  useEffect(() => {
    onMatchChange(newMatches);
  }, [newMatches]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        py: 2,
        position:'absolute',
        top: '5%',
        left: '5%',
        overflow: 'auto',
        height: '90%',
        width: '90%',
        maxHeight: 800,
        display:'block'
      }}
    >
      <Box
        sx={{
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}
      >
        <Stack direction="column" spacing={2} sx={{overflow: 'scroll'}}>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2
            }}
          >
            <Typography variant="h6">Search for new matches</Typography>
            <FormControl fullWidth sx={{maxWidth: '300px'}}>
              <InputLabel sx={{background: 'white'}}>Data Source</InputLabel>
              <Select
                labelId="select-data-source"
                id="select-data-source"
                value={selectedType}
                label="Data Source"
                onChange={(e) => {
                  setSelectedType(e.target.value as string);
                }}
              >
                <MenuItem value="pitchbook_company">Pitchbook Company</MenuItem>
                <MenuItem value="pitchbook_investor">Pitchbook Investor</MenuItem>
                <MenuItem value="pitchbook_institute">Pitchbook Institute</MenuItem>
                <MenuItem value="revelio_company">Revelio Company</MenuItem>
                <MenuItem value="global_data">Global Data</MenuItem>
                <MenuItem value="insciter_institute">Insciter Institutes</MenuItem>
                <MenuItem value="insciter_company">Insciter Companies</MenuItem>
                <MenuItem value="public_pharma">Public Pharma</MenuItem>
                <MenuItem value="affinity">Affinity</MenuItem>
                <MenuItem value="manual_institute">Curie Institute Spreadsheet</MenuItem>
                <MenuItem value="manual_investor">Curie Investor Spreadsheet</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            spacing={2}>
            <FormGroup sx={{width: '80%'}}>
              <TextField
                id="search-match"
                label="Organization Name"
                variant="outlined"
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSubmitName(searchVal)
                  }
                }}
              />
            </FormGroup>
            <Button
              variant="contained"
              disabled={!searchVal || !selectedType}
              onClick={() => setSubmitName(searchVal)}
            >
              Search
            </Button>
          </Stack>
          <ErrorBoundary Fallback={ErrorBox}>
            <Sus>
              {submitName && !!selectedType && (
                <SourceSearchResultTable
                  companyName={submitName}
                  source={selectedType}
                  matches={newMatches}
                  onMatchAdd={addMatch}
                  onMatchRemove={removeMatch}
                />
              )}
            </Sus>
          </ErrorBoundary>
        </Stack>
      </Box>
    </Modal>
  )
}

function CompanyOverview({
  company,
  addNewBtn
}: {
  company: CompanyFindResponse;
  addNewBtn: React.ReactNode;
}) {
  return (
    <>
      <Card sx={{p: 2}}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction="row" sx={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Typography variant="h6">Company Overview</Typography>
              {addNewBtn}
            </Stack>
          </Grid>
          <HalfWidthKeyPair k="Company name" val={company.companyName} />
          <HalfWidthKeyPair k="Website" val={company.website}/>
          <HalfWidthKeyPair k="Linkedin URL" val={company.companyLinkedinUrl}/>
          <HalfWidthKeyPair k="Business Status" val={company.businessStatus} />
          <HalfWidthKeyPair k="Is Company" val={company.isCompany || 'false'} />
          <HalfWidthKeyPair k="City" val={company.city} />
          <HalfWidthKeyPair k="Is Investor" val={company.isInvestor || 'false'} />
          <HalfWidthKeyPair k="State" val={company.state} />
          <HalfWidthKeyPair k="Is Institute" val={company.isInstitute || 'false'} />
          <HalfWidthKeyPair k="Country" val={company.country} />
          <HalfWidthKeyPair k="Is Academic" val={company.isAcademic || 'false'} />
        </Grid>
      </Card>
    </>
  )
}

function CompanySourceAccordion({
  source,
  sourceString,
  sourceContent,
  entityId,
  onSave,
  onMatchRemoved,
  unmatches
}: {
  source: string;
  sourceString: string;
  entityId: string;
  sourceContent: string;
  onSave: (source: string, updateRcd: any) => void;
  onMatchRemoved: (source: string, matchId: string) => void;
  unmatches: Record<string, any>;
}){
  const [expanded, setExpanded] = useState(false);
  const [edit, setEdit] = useState(false);
  const originalSourceContentRcd = JSON.parse(sourceContent);
  const [sourceContentRcd, setSourceContentRcd] = useState({...originalSourceContentRcd});
  const isValid = Object.keys(sourceContentRcd).length > 0;

  const handleUserInput = (k: string, v: string) => {
    const updateRcd = {...sourceContentRcd};
    updateRcd[k] = v;
    setSourceContentRcd(updateRcd);
  }

  const propagateSourceChanges = () => {
    const updateRcd = {};
    Object.keys(sourceContentRcd).map((k) => {
      const origVal = originalSourceContentRcd[k];
      const newVal = sourceContentRcd[k];
      if (origVal !== newVal){
        updateRcd[k] = newVal;
      }
    })
    onSave(source, updateRcd);
  }

  return (
    <Card sx={{p: 2, pt: 0}}>
      <Accordion
        disabled={!isValid || sourceString in unmatches}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={() => setExpanded(!expanded)}
        >
            <Typography>{source}</Typography>
        </AccordionSummary>
          <AccordionDetails>

            <Grid container>
              {Object.keys(sourceContentRcd).map((k) => (
                !['ROW_ID'].includes(k) && (
                  <HalfWidthKeyPair
                    edit={edit}
                    k={k}
                    val={sourceContentRcd[k]}
                    onChange={handleUserInput}
                  />
                ))
              )}
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button
                      variant="contained"
                      color={edit ? 'success' : 'primary'}
                      onClick={() => {
                        setEdit(!edit);
                        if (edit){
                          propagateSourceChanges()
                        }
                      }}
                  >
                    {edit ? 'Save' : 'Edit'}
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setExpanded(false);
                      onMatchRemoved(sourceString, entityId)
                    }}
                  >
                    Remove Match
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
      </Accordion>
    </Card>
  )
}

function NewMatchesCard({
  matches,
  onMatchRemove
}: {
  matches: CompanySourceResponse[];
  onMatchRemove: (entityId: string) => void;
}) {
  if (matches.length === 0) return null;

  const columns = React.useMemo<MRT_ColumnDef<CompanySourceResponse>[]>(
    () => [
      {
        header: 'Company Name',
        accessorKey: 'companyName',
      },
      {
        header: 'Source ID',
        accessorKey: 'entityId',
        width: 10
      },
      {
        header: 'Remove match',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={() => {
              onMatchRemove(row.original.entityId);
            }}
          >
            Remove
          </Button>
        ),
      },
    ],
    [matches]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">New Matches</Typography>
        </Grid>
        <Table
          columns={columns}
          data={matches}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}


function UnmatchesCard({
  unmatches,
  onAddMatchBack
}: {
  unmatches: Record<string, string>;
  onAddMatchBack: (source: string) => void;
}) {
  if ( Object.keys(unmatches).length === 0) return null;

  const flatChanges: Record<string, string>[]= [];
  Object.keys(unmatches).map((source) => {
    const entityId = unmatches[source];
      flatChanges.push({
        source,
        entityId,
      })
  })

  const columns = React.useMemo<MRT_ColumnDef<Record<string, string>>[]>(
    () => [
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Entity ID',
        accessorKey: 'entityId',
        width: 10
      },
      {
        header: 'Add match back',
        Cell: ({ row }) => (
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={() => {
              onAddMatchBack(row.original.source);
            }}
          >
            Add back
          </Button>
        ),
      },
    ],
    [unmatches]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Removed Matches</Typography>
        </Grid>
        <Table
          columns={columns}
          data={flatChanges}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}



function SourceChangesCard({
  sourceChanges
}: {
  sourceChanges: Record<string, Record<string, any>>;
}) {
  if ( Object.keys(sourceChanges).length === 0) return null;

  const flatChanges: Record<string, any>[]= [];
  Object.keys(sourceChanges).map((source) => {
    const sourceChangesRcds = sourceChanges[source];
    Object.keys(sourceChangesRcds).map((k) => {
      flatChanges.push({
        source,
        field: k,
        value: sourceChangesRcds[k]
      })
    })
  })

  const columns = React.useMemo<MRT_ColumnDef<Record<string, any>>[]>(
    () => [
      {
        header: 'Source',
        accessorKey: 'source',
      },
      {
        header: 'Field',
        accessorKey: 'field',
      },
      {
        header: 'Value',
        accessorKey: 'value',
      }
    ],
    [flatChanges]
  );
  return (
    <Card sx={{p: 2, m: 2}}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">Source Changes Made</Typography>
        </Grid>
        <Table
          columns={columns}
          data={flatChanges}
          enableTopToolbar={false}
          perPage={100}
        />
      </Grid>
    </Card>
  )
}

function SearchFoundCompany({ curieOrgId }: { curieOrgId: string }){

  const [completed, setCompleted] = useState(false);
  const { apiPersistence } = useAPI();
  const [searchForNewOpen, setSearchForNewOpen] = useState(false);
  const [newMatches, setNewMatches] = useState<CompanySourceResponse[]>([]);
  const [removedMatches, setRemovedMatches] = useState<Record<string, string>>({});
  const [sourceChanges, setSourceChanges] = useState<Record<string, Record<string, any>>>({});

  const inputData = { curieOrgId };

  const { data } = apiPersistence.useCompanyFind(
    hookCacheKey('useCompanyFind', inputData),
    {input: inputData}
  );

  const company = data?.companyFind as CompanyFindResponse;

  const generateResult = () => {
    const removedArray: Record<string, string>[] = [];
    Object.keys(removedMatches).map((source) => {
      removedArray.push({
        source,
        entityId: removedMatches[source]
      })
    })
    const result: SubmitData = {
      original: company,
      newMatches: newMatches,
      removedMatches: removedArray,
      sourceChanges: sourceChanges,
      submittedTime: new Date().getTime()
    }
    return result
  }

  const onSubmit = async () => {
    const result = generateResult()
    document.body.style.cursor = 'wait';
    await apiPersistence.saveCompanyFix({
      input: {
        result: JSON.stringify(result)
      }
    });
    document.body.style.cursor = 'default';
    setCompleted(true)
  }

  useEffect(() => {
    setCompleted(false)
  }, [curieOrgId])

  if (completed){
    return <Navigate to="/search" replace />;
  }

  const removeMatch = (e: string) => {
    const removedMatches = [...newMatches].filter((m) => m.entityId !== e);
    setNewMatches([...removedMatches]);
  }

  const handleSourceChange = (source: string, updateRcd: any) => {
    const updateRcds = {...sourceChanges};
    updateRcds[source] = updateRcd;
    setSourceChanges(updateRcds);
  }

  const setMatchedRemoved = (source: string, matchId: string) => {
    const updatedRemovedMatches = {...removedMatches};
    updatedRemovedMatches[source] = matchId;
    setRemovedMatches({...updatedRemovedMatches});
  }

  const hasChange = newMatches.length > 0
    || Object.keys(removedMatches).length > 0
    || Object.keys(sourceChanges).length > 0;

  return (
    <>
      <Stack direction="column" spacing={2}>
        <CompanyOverview
          company={company}
          addNewBtn={
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setSearchForNewOpen(true)}
              >
                Add new matches
              </Button>
              <Button
                variant="contained"
                color="success"
                disabled={!hasChange}
                onClick={onSubmit}
              >
                Save
              </Button>
            </Stack>
            }
        />
        <NewMatchesCard matches={newMatches} onMatchRemove={removeMatch} />
        <UnmatchesCard
          unmatches={removedMatches}
          onAddMatchBack={(source) => {
          const existingUnmatches = {...removedMatches}
          if (source in existingUnmatches){
            delete existingUnmatches[source];
            setRemovedMatches({...existingUnmatches});
          }
        }} />
        <SourceChangesCard sourceChanges={sourceChanges} />
        <CompanySourceAccordion
          source="Pitchbook Company"
          sourceContent={company.pitchbookCompany}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='pitchbook_company'
          entityId={company.pitchbookOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Pitchbook Investor"
          sourceContent={company.pitchbookInvestor}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='pitchbook_investor'
          entityId={company.pitchbookOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Pitchbook Institute"
          sourceContent={company.pitchbookInstitute}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='pitchbook_institute'
          entityId={company.pitchbookOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Pitchbook Other Org"
          sourceContent={company.pitchbookOtherOrg}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='pitchbook_other_org'
          entityId={JSON.parse(company.pitchbookOtherOrg)?.PITCHBOOK_OTHER_ORG_ID || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Investors Bronze Spreadsheet"
          sourceContent={company.investorsBronze}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='manual_investor'
          entityId={company.pitchbookOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Revelio Company"
          sourceContent={company.revelioCompany}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='revelio_company'
          entityId={company.revelioOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Public Pharma"
          sourceContent={company.publicPharma}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='public_pharma'
          entityId={company.publicPharmaOrgId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Global Data"
          sourceContent={company.globalData}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='global_data'
          entityId={company.globalDataCompanyId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Manual Institute"
          sourceContent={company.manualInstitute}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='manual_institute'
          entityId={company.manualInstituteId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Insciter Company"
          sourceContent={company.insciterCompany}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='insciter_company'
          entityId={company.insciterCompanyId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Insciter Institute"
          sourceContent={company.insciterInstitute}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='insciter_institute'
          entityId={company.insciterInstituteId || ''}
          unmatches={removedMatches}
        />
        <CompanySourceAccordion
          source="Affinity"
          sourceContent={company.affinityCompany}
          onSave={handleSourceChange}
          onMatchRemoved={setMatchedRemoved}
          sourceString='affinity'
          entityId={company.affinityOrgId || ''}
          unmatches={removedMatches}
        />
      </Stack>
      <AddNewMatchesModal
        open={searchForNewOpen}
        onClose={() => setSearchForNewOpen(false)}
        onMatchChange={setNewMatches}
      />
    </>
  )
}

export function SearchCompanyPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const curieOrgId = searchParams.get('curie_org_id');

  return (
    <DocumentTitle title="Page title">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection>
          <ErrorBoundary Fallback={ErrorBox}>
            <Sus>
              {!!curieOrgId  && (<SearchFoundCompany curieOrgId={curieOrgId} />)}
            </Sus>
          </ErrorBoundary>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
