import React from 'react';
import * as DocumentTitle from 'react-document-title';
import { Button, Stack, Typography } from '@mui/material';
import { ContainerViewport, ContentSection, Link } from 'mui/components';
import { httpGet } from 'api/http';
import { APP_CONFIG } from 'config/appConfig';

interface AuthResponse {
  authorization_url: string;
}

function PageContent() {

  const loginUser = async() => {
    const resp: AuthResponse = await httpGet('/api/auth/login');
    window.location.href = resp.authorization_url;
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{alignItems: 'center'}}>
      <Link to='/surveys'>
        <Button
          variant="contained"
          onClick={loginUser}
        >
          Login
        </Button>
      </Link>
    </Stack>
  );
}

export function LoginPage() {

  return (
    <DocumentTitle title="Login">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection sx={{height: '100%', justifyContent: 'center'}}>
          <PageContent />
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
