import React from 'react';
import { Link as RouterLink, To } from 'react-router-dom';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';

/**
 * This is a wrapper component - MuiLink styling inside a router link, so we can benefit
 * from shallow routing within the application while still using the MUI styles.
 * We currently have several issues with existing global .scss that we try to cover here.
 * @TODO - Unit Test.
 **/
export function Link({ to, children, ...rest }: { to: To } & MuiLinkProps) {
  return (
    <MuiLink component={RouterLink} to={to} underline="none" {...rest}>
      {children}
    </MuiLink>
  );
}

export function ExternalLink({
  to,
  children,
  ...rest
}: { to: string } & MuiLinkProps) {
  return (
    <MuiLink
      component="a"
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      {...rest}
    >
      {children}
    </MuiLink>
  );
}
