import React from 'react';

import {
  BaseTable,
  PublicCommonTableProps,
  PublicTableProps,
} from '../BaseTable';

/**
 * Table that supports data in memory use cases. Light wrapper
 * around BaseTable to only expose the relevant props.
 */
export function Table<TData>({
  data,
  columns,
  enableTopToolbar,
  enableGlobalFilter,
  searchPlaceholder,
  enableGrouping,
  manualGrouping,
  getSubRows,
  groupedRowAction,
  rowAction,
  enableSorting,
  defaultSortColIndex = 0,
  defaultSortDesc = false,
  enablePagination = true,
  perPage = 10,
  bodyRowHeight,
  renderBottomToolbarCustomActions,
  renderDetailPanel,
}: {
  data: TData[];
  defaultSortColIndex?: number;
  defaultSortDesc?: boolean;
  perPage?: number;
} & PublicCommonTableProps<TData> &
  PublicTableProps) {
  const sortCol = columns[defaultSortColIndex].accessorKey as string;
  const defaultSorting = [{ id: sortCol, desc: defaultSortDesc }];

  return (
    <BaseTable
      data={data}
      columns={columns}
      enableTopToolbar={enableTopToolbar}
      enableGlobalFilter={enableGlobalFilter}
      searchPlaceholder={searchPlaceholder}
      enableGrouping={enableGrouping}
      manualGrouping={manualGrouping}
      getSubRows={getSubRows}
      groupedRowAction={groupedRowAction}
      rowAction={rowAction}
      enableSorting={enableSorting}
      sorting={enableSorting ? defaultSorting : undefined}
      enablePagination={enablePagination}
      pagination={{ pageIndex: 0, pageSize: perPage }}
      bodyRowHeight={bodyRowHeight}
      renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      renderDetailPanel={renderDetailPanel}
    />
  );
}
