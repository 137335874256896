import React from 'react';
import * as DocumentTitle from 'react-document-title';
import { MRT_ColumnDef } from 'material-react-table';
import { Checkbox, Grid, Stack, Typography } from '@mui/material';
import { ContainerViewport, ContentSection, Table } from 'mui/components';

import { User, UserListResponse } from 'api/graphql';
import { useAPI } from 'hooks';


function ActiveUsersTable({
  currentUser,
  users
}: {
  currentUser: User,
  users: User[]
}) {
  const { apiPersistence } = useAPI();
  const [userRecords, setUserRecords] = React.useState<User[]>(users);

  const updateUserInRecords = (user: User) => {
    const updatedUsers = [...userRecords].map((u) => {
      if (u.email !== user.email){
        return u;
      }
      else {
        return user
      }
    });
    setUserRecords(updatedUsers);
  }

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Active',
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.isActive}
            onChange={async (e) => {
              const user = row.original;
              user.isActive = e.target.checked;
              const resp = await apiPersistence.userUpdate({input: user})
              if (resp.userUpdate.__typename === 'SuccessResponse'){
                updateUserInRecords(user);
              }
            }}
          />
        )
      },
      {
        header: 'Admin',
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.isAdmin}
            disabled={currentUser.email === row.original.email}
            onChange={async (e) => {
              const user = row.original;
              user.isAdmin = e.target.checked;
              const resp = await apiPersistence.userUpdate({input: user})
              if (resp.userUpdate.__typename === 'SuccessResponse'){
                updateUserInRecords(user)
              }
            }}
          />
      ),
      }
    ],
    []
  );
  return (
    <Table
      columns={columns}
      data={users}
      enableTopToolbar={false}
      perPage={100}
    />
  )
}


export function AdminPage() {

  const { apiPersistence, apiHttp } = useAPI();
  const { data: currentUser } = apiHttp.useAuthUser()
  const { data, mutate } = apiPersistence.useListUsers('useListUsers');

  const allUsers = data?.listUsers as UserListResponse;

  return (
    <DocumentTitle title="Home">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection sx={{justifyContent: 'center'}}>
          <Grid container>
            <Grid item >
              <Stack direction="column" spacing={2}>
                <Typography variant="h5">
                  Users
                </Typography>
                <ActiveUsersTable
                  currentUser={currentUser as unknown as User}
                  users={allUsers.users}
                />
              </Stack>
            </Grid>
          </Grid>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
