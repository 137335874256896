import React from 'react';
import { useSearchParams } from 'react-router-dom'
import * as DocumentTitle from 'react-document-title';

import {
  ContainerViewport,
  ErrorBoundary,
  ErrorBox,
  ContentSection,
  SelectSurvey,
  Sus,
} from 'mui/components';
import { CompanySurvey } from './CompanySurvey';


export function SurveyPage({ practice = false }: { practice?: boolean }) {

  const [searchParams, setSearchParams] = useSearchParams()
  const getSurveyComponent = () => {
    const surveyType = searchParams.get('survey_type');
    if (surveyType === 'company') {
      return <CompanySurvey practice={practice} />;
    }
    return <SelectSurvey practice={practice} />;
  }

  const SurveyComponent = getSurveyComponent();

  return (
    <DocumentTitle title="Page title">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection>
          <ErrorBoundary Fallback={ErrorBox}>
            <Sus>
              {SurveyComponent}
            </Sus>
          </ErrorBoundary>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
