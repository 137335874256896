import React from 'react';
import { Navigate } from 'react-router';
import { useAPI } from 'hooks';


export function ProtectedRoute ({ children }) {
  const { apiHttp } = useAPI();
  const { data } = apiHttp.useAuthUser()

  if (!data){
    return null;
  }

  if (!data?.email) {
    return <Navigate to="/login" replace />;
  }

  if (!!data.email) {
    return children;
  }

  return null;
};

export function ProtectedAdminRoute ({ children }) {
  const { apiHttp } = useAPI();
  const { data } = apiHttp.useAuthUser()

  if (!data){
    return null;
  }

  if (!data?.email) {
    return <Navigate to="/login" replace />;
  }

  if (!data?.is_admin) {
    return <Navigate to="/" replace />;
  }

  if (!!data.email) {
    return children;
  }

  return null;
};
