import React from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import * as DocumentTitle from 'react-document-title';
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';

import { CompanySearchListResponse, PersonSearchListResponse, PersonSearchResponse } from 'api/graphql';
import {
  ContainerViewport,
  ErrorBoundary,
  ErrorBox,
  ContentSection,
  Link,
  Sus,
  Table,
} from 'mui/components';
import { useAPI, hookCacheKey } from 'hooks';

function CompanySearchResultTable({ companyName }: { companyName: string }){

  const { apiPersistence } = useAPI();

  const inputData = { companyName };
  const { data } = apiPersistence.useCompaniesSearch(
    hookCacheKey('useGetNextCompanySurvey', inputData),
    {input: inputData}
  );

  const companySurveys = data?.companiesSearch as CompanySearchListResponse;
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Company Name',
        Cell: ({ row }) => (
          <Link to={`/search/company?curie_org_id=${row.original.curieOrgId}`}>
            {row.original.companyName}
          </Link>
        ),
        cellWidth: '27%'
      },
      {
        header: 'Matches',
        Cell: ({ row }) => {
          const matches: string[] = []
          if (row.original.pitchbookOrgId) {
            matches.push('Pitchbook');
          }
          if (row.original.revelioOrgId) {
            matches.push('Revelio');
          }
          if (row.original.globalDataCompanyId) {
            matches.push('GlobalData');
          }
          if (row.original.insciterCompanyId) {
            matches.push('InsciterCompany');
          }
          if (row.original.insciterInstituteId) {
            matches.push('InsciterInstitute');
          }
          if (row.original.affinityOrgId) {
            matches.push('Affinity');
          }
          if (row.original.publicPharmaOrgId) {
            matches.push('PublicPharma');
          }
          if (row.original.manualInstituteId) {
            matches.push('ManualInstitute');
          }
          return matches.map((match, index) => <div key={index}>{match}</div>)
        },
        cellWidth: '22%'
      },
      {
        header: 'Website',
        accessorKey: 'website',
        cellWidth: '16%'
      },
      {
        header: 'Is Company',
        cellWidth: '10%',
        Cell: ({ row }) => {
          return row.original.isCompany ? 'Y' : null;
        }
      },
      {
        header: 'Is Investor',
        cellWidth: '10%',
        Cell: ({ row }) => {
          return row.original.isInvestor ? 'Y' : null;
        }
      },
      {
        header: 'Is Institute',
        cellWidth: '10%',
        Cell: ({ row }) => {
          return row.original.isInstitute ? 'Y' : null;
        }
      },
    ],
    []
  );
  return (
    <Table
      columns={columns}
      data={companySurveys.companies}
      enableTopToolbar={false}
      perPage={100}
    />
  )
}


function CompanySearchTable(){

  const [companyName, setCompanyName] = React.useState<string | null>(null);
  const [companySearchName, setCompanySearchName] = React.useState<string | null>(null);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} sx={{width: '100%'}}>
        <TextField
          id="Company name"
          label="Company name"
          variant="outlined"
          placeholder='Enter company name exactly as it appears in the database'
          sx={{width: '100%'}}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setCompanySearchName(companyName)
            }
          }}
          onChange={(e) => {
            setCompanyName(e.target.value as string);
          }}
        />
        <Button
          variant="contained"
          onClick={() => setCompanySearchName(companyName)}
        >
          Submit
        </Button>
      </Stack>
      <ErrorBoundary Fallback={ErrorBox}>
        <Sus>
          {companySearchName && (
            <CompanySearchResultTable companyName={companySearchName} />
          )}
        </Sus>
      </ErrorBoundary>
    </Stack>
  )
}


type PersonSearch = {
  firstName?: string;
  lastName?: string;
}

function PersonSearchResultTable({
  personSearch
}: {
  personSearch: PersonSearch;
}){

  const { apiPersistence } = useAPI();

  const inputData = {
    firstName: personSearch.firstName || '',
    lastName: personSearch.lastName || ''
  };
  const { data } = apiPersistence.usePersonSearch(
    hookCacheKey('usePersonSearch', inputData),
    {input: inputData}
  );

  const people = data?.personSearch as PersonSearchListResponse;
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: 'Person Name',
        Cell: ({ row }) => (
          <Link to={`/search/person?curie_person_id=${row.original.curiePersonId}`}>
            {row.original.fullName}
          </Link>
        ),
        cellWidth: '27%'
      },
      {
        header: 'Matches',
        Cell: ({ row }) => {
          const matches: string[] = []
          if (row.original.pitchbookPersonId) {
            matches.push('Pitchbook');
          }
          if (row.original.revelioPersonId) {
            matches.push('Revelio');
          }
          if (row.original.insciterPersonId) {
            matches.push('Insciter');
          }
          if (row.original.affinityPersonId) {
            matches.push('Affinity');
          }
          return matches.map((match, index) => <div key={index}>{match}</div>)
        },
        cellWidth: '22%'
      },
      {
        header: 'Employers',
        Cell: ({ row }) => {
          return row.original.employers.map((emp, index) => <div key={index}>{emp}</div>)
        },
        cellWidth: '22%'
      },
      {
        header: 'Primary Position',
        accessorKey: 'primaryPosition',
      },
      {
        header: 'Linkedin',
        Cell: ({ row }) => (
          <Link to={`https://${row.original.personLinkedinUrl}`}>
            {row.original.personLinkedinUrl}
          </Link>
        ),
      }
    ],
    []
  );
  return (
    <Table
      columns={columns}
      data={people.people}
      enableTopToolbar={false}
      perPage={100}
    />
  )
}


function PersonSearchTable(){

  const [personSearch, setPersonSearch] = React.useState<PersonSearch | null>(null);
  const [searchPersonSearch, setSearchPersonSearch] = React.useState<PersonSearch | null>(null);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} sx={{width: '100%'}}>
        <TextField
          id="First name"
          label="First name"
          variant="outlined"
          placeholder='Enter first name exactly as it appears in the database'
          sx={{width: '100%'}}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchPersonSearch({...personSearch})
            }
          }}
          onChange={(e) => {
            setPersonSearch({
              ...personSearch,
              firstName: e.target.value as string
            });
          }}
        />
        <TextField
          id="Last name"
          label="Last name"
          variant="outlined"
          placeholder='Enter last name exactly as it appears in the database'
          sx={{width: '100%'}}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearchPersonSearch({...personSearch})
            }
          }}
          onChange={(e) => {
            setPersonSearch({
              ...personSearch,
              lastName: e.target.value as string
            });
          }}
        />
        <Button
          variant="contained"
          onClick={() => setSearchPersonSearch({...personSearch})}
        >
          Submit
        </Button>
      </Stack>
      <ErrorBoundary Fallback={ErrorBox}>
        <Sus>
          {searchPersonSearch && (
            <PersonSearchResultTable personSearch={searchPersonSearch} />
          )}
        </Sus>
      </ErrorBoundary>
    </Stack>
  )
}


export function SearchForSurveyPage() {

  const [searchType, setSelectedSearchType] = React.useState<string | null>(null);

  const getSearchResultComponent = () => {
    if (searchType === 'company') {
      return <CompanySearchTable />;
    }
    if (searchType === 'person') {
      return <PersonSearchTable />;
    }
  }

  return (
    <DocumentTitle title="Page title">
      <ContainerViewport sx={{ display: 'flex' }}>
        <ContentSection>
          <Stack direction="column" spacing={2}>
            <>
              <InputLabel sx={{mt: 1}}>Select the type of search</InputLabel>
              <Select
                labelId="search-type-label"
                id="search-type"
                value={searchType}
                label="Select the type of search"
                onChange={(e) => {
                  setSelectedSearchType(e.target.value as string)
                }}
              >
                <MenuItem value="company">Company</MenuItem>
                <MenuItem value="person">Person</MenuItem>
              </Select>
            </>
            {getSearchResultComponent()}
          </Stack>
        </ContentSection>
      </ContainerViewport>
    </DocumentTitle>
  );
}
