import React from 'react';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import SearchOffRoundedIcon from '@mui/icons-material/SearchOffRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from 'material-react-table';
import type {
  PaginationState,
  SortingState,
  RowData,
} from '@tanstack/react-table';
import { collapseClasses, tablePaginationClasses } from '@mui/material';

// Typing for Meta object:
// https://tanstack.com/table/v8/docs/api/core/table#meta
declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    refetch?: () => void;
  }
}

export interface PublicCommonTableProps<TData> {
  columns: MRT_ColumnDef<any>[];
  enableTopToolbar?: boolean;
  enableGlobalFilter?: boolean;
  searchPlaceholder?: string;
  enableGrouping?: boolean;
  getSubRows?: (originalRow: TData, index: number) => undefined | RowData[];
  groupedRowAction?: (row: MRT_Row<any>) => void;
  rowAction?: (row: MRT_Row<any>) => void;
  bodyRowHeight?: number;
  renderBottomToolbarCustomActions?: ({
    table,
  }: {
    table: MRT_TableInstance<TData>;
  }) => React.ReactNode;
  renderDetailPanel?: ({
    row,
    table,
  }: {
    table: MRT_TableInstance<TData>;
    row: MRT_Row<TData>;
  }) => React.ReactNode;
}

export interface PrivateCommonTableProps {
  pagination?: PaginationState;
  sorting?: SortingState;
}

export interface PublicTableProps {
  enablePagination?: boolean;
  enableSorting?: boolean;
  manualGrouping?: boolean;
}

export interface PrivateRemoteTableProps {
  manualFiltering?: boolean;
  manualPagination?: boolean;
  manualSorting?: boolean;
  onGlobalFilterChange?: (globalFilter: string | undefined) => void;
  globalFilter?: string;
  onPaginationChange?: (pagination: PaginationState) => void;
  onSortingChange?: (sorting: SortingState) => void;
  rowCount?: number;
  isLoading?: boolean;
  isRefetching?: boolean;
  refetch?: () => void;
  tableInstanceRef?:
    | React.MutableRefObject<MRT_TableInstance<any> | null>
    | undefined;
}

/**
 * Wrapper around MaterialReactTable that implements any specific
 * styling, default settings and custom functionality that is shared by
 * both Table and RemoteTable.
 *
 * This component is not to be used directly as a shared component, only
 * Table and RemoteTable which have a more opinionated set of props.
 */
export function BaseTable<TData extends RowData>({
  // props shared by Table and RemoteTable
  data,
  columns,
  enableTopToolbar = true,
  enableGlobalFilter = true,
  searchPlaceholder = 'Search...',
  enableGrouping = false,
  manualGrouping = false,
  getSubRows,
  groupedRowAction,
  rowAction,
  sorting,
  enablePagination = true,
  pagination,
  // props specific to Table
  enableSorting = false,
  // props specific to RemoteTable
  manualFiltering = false,
  manualPagination = false,
  manualSorting = false,
  onGlobalFilterChange,
  globalFilter,
  onPaginationChange,
  onSortingChange,
  rowCount,
  isLoading = false,
  isRefetching = false,
  refetch,
  tableInstanceRef,
  bodyRowHeight,
  renderBottomToolbarCustomActions,
  renderDetailPanel,
}: {
  data: TData[];
} & PublicCommonTableProps<TData> &
  PublicTableProps &
  PrivateCommonTableProps &
  PrivateRemoteTableProps) {
  const defaultGroupingCol = columns[0].accessorKey as string;

  // When paginating manually, RemoteTable should be updating state remotely.
  // Therefore, we must pass any state that may change to MRT under state
  // instead of initialState.
  const manualPaginationState = manualPagination
    ? {
        globalFilter,
        isLoading,
        showProgressBars: isRefetching,
        ...(pagination ? { pagination } : {}),
        ...(sorting ? { sorting } : {}),
      }
    : {};

  // When not paginating manually, Table should not be updating state remotely.
  // Therefore, we must pass any state to MRT under initialState otherwise MRT
  // will not be able to update it's own state to support built-in features
  // such as sorting and pagination.
  const manualPaginationInitialState = manualPagination
    ? {}
    : {
        showAlertBanner: false,
        ...(pagination ? { pagination } : {}),
        ...(sorting ? { sorting } : {}),
      };

  const manualPaginationProps = manualPagination
    ? {
        onGlobalFilterChange,
        onPaginationChange,
        onSortingChange,
        rowCount,
      }
    : {};

  return (
    <MaterialReactTable
      // Props
      columns={columns}
      data={data}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={enablePagination}
      enableBottomToolbar={enablePagination}
      enableSorting={enableSorting}
      enableTopToolbar={enableTopToolbar}
      enableToolbarInternalActions={false}
      enableGlobalFilter={enableGlobalFilter}
      positionGlobalFilter="left"
      enableColumnDragging={false}
      enableGrouping={enableGrouping}
      manualGrouping={manualGrouping}
      enableExpanding={manualGrouping || enableGrouping}
      getSubRows={manualGrouping ? getSubRows : undefined}
      // do not re-order the column order when grouping.
      // this ensures the expand icon stays in the first column.
      groupedColumnMode={false}
      // There appears to be a bug with MRT where when using manualPagination,
      // the only way to force MRT to display expanded rows is to set
      // paginateExpandedRows to true. Of course, since pagination is manual,
      // this does not actually change what page data is displayed on.
      // See https://github.com/TanStack/table/blob/191406a179ac1291fcd5fd303acdaf6fcf612671/packages/table-core/src/utils/getExpandedRowModel.ts#L22.
      paginateExpandedRows={manualPagination}
      manualFiltering={manualFiltering}
      manualPagination={manualPagination}
      manualSorting={manualSorting}
      meta={!!refetch ? { refetch } : {}}
      {...manualPaginationProps}
      tableInstanceRef={tableInstanceRef}
      // State
      // Certain state must be provided in initialState. Due to how MRT is
      // initialized providing it in state will load to unintended behavior.
      initialState={{
        showGlobalFilter: true,
        // When grouping manually, the data must already be in a grouped structure.
        grouping: enableGrouping && !manualGrouping ? [defaultGroupingCol] : [],
        ...manualPaginationInitialState,
      }}
      state={{
        // common state
        density: 'compact',
        ...manualPaginationState,
      }}
      muiTablePaperProps={{
        elevation: 0,
        square: true,
      }}
      muiTopToolbarProps={{
        sx: {
          backgroundColor: 'unset',
          p: 0, // override MRT's use of !important
          '&&': { p: 1.5 }, // override MRT default
          // enable fullWidth muiSearchTextField
          display: 'flex',
          [`.${collapseClasses.root}, .${collapseClasses.wrapperInner}`]: {
            width: '100%',
          },
        },
      }}
      muiTableContainerProps={{
        sx: { maxHeight: '100%' },
      }}
      muiTableProps={{
        sx: { tableLayout: 'fixed' },
      }}
      muiExpandAllButtonProps={({ table }) => {
        return {
          sx: {
            // Do not display an expand icon unless at least one row can expand.
            display: !table.getCanSomeRowsExpand() ? 'none' : undefined,
            height: 'unset',
            width: 'unset',
          },
        };
      }}
      muiExpandButtonProps={({ row }) => {
        return {
          sx: {
            // Do not display an expand icon unless the row can expand.
            display: !row.getCanExpand() ? 'none' : undefined,
            height: 'unset',
            width: 'unset',
          },
        };
      }}
      // Default column display options
      displayColumnDefOptions={{
        'mrt-row-expand': {
          maxSize: 56,
        },
      }}
      defaultColumn={{
        // For manual pagination, sorting must be enabled on each column
        // definition by the implementor since we do not know which columns
        // the remote data fetch supports sorting on.
        enableSorting: !manualPagination,
      }}
      // Header props
      muiTableHeadRowProps={{
        sx: {
          backgroundColor: 'unset',
          boxShadow: 'none',
        },
      }}
      muiTableHeadCellProps={{
        sx: (theme) => ({
          px: 2,
          fontWeight: theme.typography.subtitle2.fontWeight,
        }),
      }}
      // We must disable the alert banner altogether because grouped and
      // selected columns are automatically displayed and cannot be disabled.
      muiToolbarAlertBannerProps={{ sx: { display: 'none' } }}
      // Body props
      muiTableBodyRowProps={({ row, isDetailPanel }) => {
        // The Detail Panel rows are rendered as any other row.
        // the only difference is the presence of this attribute set to true.
        // We do not want custom styling or actions on detail panels.
        if (isDetailPanel) {
          return {};
        }

        const commonSx = {
          backgroundColor: 'unset',
          ...(!!bodyRowHeight ? { height: bodyRowHeight } : {}),
        };

        if (row.depth > 0) {
          return groupedRowAction
            ? {
                onClick: () => groupedRowAction(row),
                sx: { ...commonSx, cursor: 'pointer' },
              }
            : { hover: false, sx: commonSx };
        }
        return rowAction
          ? {
              onClick: () => rowAction(row),
              sx: { ...commonSx, cursor: 'pointer' },
            }
          : { hover: false, sx: commonSx };
      }}
      muiTableBodyCellProps={{
        sx: {
          px: 2,
          whiteSpace: 'normal',
          textOverflow: 'clip',
        },
      }}
      // Footer props
      muiBottomToolbarProps={{
        sx: {
          backgroundColor: 'unset',
          boxShadow: 'none',
          minHeight: 56,
        },
      }}
      renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      // On DetailPanel usage - enable expand buttons.
      getRowCanExpand={renderDetailPanel ? () => true : undefined}
      renderDetailPanel={renderDetailPanel}
      muiTablePaginationProps={{
        sx: (theme) => ({
          [`.${tablePaginationClasses.selectLabel}`]: {
            color: theme.palette.text.secondary,
          },
        }),
      }}
      muiSearchTextFieldProps={{
        variant: 'outlined',
        label: 'Search',
        placeholder: searchPlaceholder,
        size: 'small',
        fullWidth: true,
        InputLabelProps: { shrink: true },
      }}
      // https://github.com/KevinVandy/material-react-table/blob/main/packages/material-react-table/src/icons.ts
      icons={{
        CloseIcon: (props) => <CloseRoundedIcon {...props} />,
        ExpandLessIcon: (props) => <ExpandLessRoundedIcon {...props} />,
        ExpandMoreIcon: (props) => <ExpandMoreRoundedIcon {...props} />,
        KeyboardDoubleArrowDownIcon: (props) => (
          <KeyboardDoubleArrowDownRoundedIcon {...props} />
        ),
        SearchIcon: (props) => <SearchRoundedIcon {...props} />,
        SearchOffIcon: (props) => <SearchOffRoundedIcon {...props} />,
      }}
    />
  );
}
