import React from 'react';

import {
  Box,
  BoxProps,
  CardContent,
  CardContentProps,
  Container,
  ContainerProps,
  Paper,
  PaperProps,
  SxProps,
  Stack,
  StackProps,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import {
  DefaultLoader,
  ErrorBoundary,
  FallbackProps,
  ErrorBox,
  Sus,
} from 'mui/components';

export function BoxCentered({ sx, children, ...rest }: BoxProps) {
  return (
    <Box
      {...rest}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100%',
          minWidth: '100%',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
}

export function BoxEmptyState({ sx, children, ...rest }: BoxProps) {
  return (
    <BoxCentered
      p={2}
      {...rest}
      sx={[
        (theme) => ({
          color: alpha(theme.palette.common.black, 0.6),
          typography: 'body1',
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </BoxCentered>
  );
}

export function ContainerViewport({ children, sx, ...rest }: ContainerProps) {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={[
        {
          height: 'calc(100vh - 64px)',
          display: 'grid',
          gridTemplateRows: 'minmax(48px, auto) 1fr',
          gridTemplateColumns: '1fr',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Container>
  );
}

export function HeaderSection({ children, sx, ...rest }: PaperProps) {
  return (
    <Paper
      elevation={1}
      square
      sx={[
        (theme) => ({
          position: 'relative',
          zIndex: theme.zIndex.drawer,
          px: 2,
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {children}
    </Paper>
  );
}

export function ContentSection({
  children,
  scrollSx,
  ...rest
}: { scrollSx?: SxProps } & StackProps) {
  return (
    <Box
      sx={[
        {
          display: 'block',
          overflowY: 'auto',
          width: '100%',
          pt: 2,
          pb: 3,
        },
        ...(Array.isArray(scrollSx) ? scrollSx : [scrollSx]),
      ]}
    >
      <Stack
        direction="column"
        maxWidth="lg"
        gap={1}
        px={2}
        mx="auto"
        {...rest}
      >
        {children}
      </Stack>
    </Box>
  );
}

export function DynamicCardContent({
  Fallback = ErrorBox,
  loader = <DefaultLoader />,
  children,
  ...rest
}: {
  Fallback?: React.ComponentType<FallbackProps>;
  loader?: React.ReactNode;
  children;
} & CardContentProps) {
  return (
    <CardContent {...rest}>
      <ErrorBoundary Fallback={Fallback}>
        <Sus fallback={loader}>{children}</Sus>
      </ErrorBoundary>
    </CardContent>
  );
}
