/* == THIS IS A GENERATED FILE!!! See codegen.yml and do not edit by hand == */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable id-blacklist */
import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { ClientError } from 'graphql-request/dist/types';
import useSWR, {
  SWRConfiguration as SWRConfigInterface,
  Key as SWRKeyInterface,
} from 'swr';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddResponse =
  | ApplicationError
  | InputError
  | NotFoundError
  | SuccessResponse
  | ValidationError;

export type ApplicationError = GraphQlError & {
  __typename: 'ApplicationError';
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type Company = {
  __typename: 'Company';
  bio?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  hqLocation?: Maybe<Scalars['String']>;
  linkedinUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  source: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type CompanyFindInput = {
  curieOrgId: Scalars['String'];
};

export type CompanyFindResponse = {
  __typename: 'CompanyFindResponse';
  affinityCompany: Scalars['String'];
  affinityOrgId?: Maybe<Scalars['String']>;
  businessStatus?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  curieOrgId?: Maybe<Scalars['String']>;
  globalData: Scalars['String'];
  globalDataCompanyId?: Maybe<Scalars['String']>;
  insciterCompany: Scalars['String'];
  insciterCompanyId?: Maybe<Scalars['String']>;
  insciterInstitute: Scalars['String'];
  insciterInstituteId?: Maybe<Scalars['String']>;
  investorsBronze: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  isCompany: Scalars['Boolean'];
  isInstitute: Scalars['Boolean'];
  isInvestor: Scalars['Boolean'];
  manualInstitute: Scalars['String'];
  manualInstituteId?: Maybe<Scalars['String']>;
  pitchbookCompany: Scalars['String'];
  pitchbookInstitute: Scalars['String'];
  pitchbookInvestor: Scalars['String'];
  pitchbookOrgId?: Maybe<Scalars['String']>;
  pitchbookOtherOrg: Scalars['String'];
  publicPharma: Scalars['String'];
  publicPharmaOrgId?: Maybe<Scalars['String']>;
  revelioCompany: Scalars['String'];
  revelioInstitute: Scalars['String'];
  revelioOrgId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanySearchInput = {
  companyName: Scalars['String'];
};

export type CompanySearchListResponse = {
  __typename: 'CompanySearchListResponse';
  companies: Array<CompanySearchResponse>;
};

export type CompanySearchResponse = {
  __typename: 'CompanySearchResponse';
  affinityOrgId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  curieOrgId?: Maybe<Scalars['String']>;
  globalDataCompanyId?: Maybe<Scalars['String']>;
  insciterCompanyId?: Maybe<Scalars['String']>;
  insciterInstituteId?: Maybe<Scalars['String']>;
  isCompany: Scalars['Boolean'];
  isInstitute: Scalars['Boolean'];
  isInvestor: Scalars['Boolean'];
  manualInstituteId?: Maybe<Scalars['String']>;
  pitchbookOrgId?: Maybe<Scalars['String']>;
  publicPharmaOrgId?: Maybe<Scalars['String']>;
  revelioOrgId?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type CompanySourceListResponse = {
  __typename: 'CompanySourceListResponse';
  companies: Array<CompanySourceResponse>;
};

export type CompanySourceResponse = {
  __typename: 'CompanySourceResponse';
  companyLinkedinUrl?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  entityId: Scalars['String'];
  source: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

export type CompanySourceSearchInput = {
  companyName: Scalars['String'];
  source: Scalars['String'];
};

export type CompanySurveyListResponse = {
  __typename: 'CompanySurveyListResponse';
  surveys: Array<CompanySurveyResponse>;
};

export type CompanySurveyResponse = {
  __typename: 'CompanySurveyResponse';
  companyA: Company;
  companyB: Company;
  matchId: Scalars['String'];
};

export type CompletedSurveyInput = {
  surveyResponse: Scalars['String'];
  surveyType: Scalars['String'];
};

export type FieldError = {
  __typename: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
  path: Scalars['String'];
};

export type FixInput = {
  result: Scalars['String'];
};

export type GetCompanyFindResponse =
  | ApplicationError
  | CompanyFindResponse
  | NotFoundError;

export type GetCompanySurveyResponse =
  | ApplicationError
  | CompanySurveyResponse
  | NoMoreSurveysOfThisTypeResponse
  | NotFoundError;

export type GetPersonFindResponse =
  | ApplicationError
  | NotFoundError
  | PersonFindResponse;

export type GetUserSurveyMetricsResponse =
  | ApplicationError
  | NotFoundError
  | UserSurveyMetricsResponse;

export type GraphQlError = {
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type GraphQlSuccess = {
  message: Scalars['String'];
};

export type InputError = GraphQlError & {
  __typename: 'InputError';
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type ListCompanySearchResponse =
  | ApplicationError
  | CompanySearchListResponse;

export type ListCompanySourceResponse =
  | ApplicationError
  | CompanySourceListResponse;

export type ListCompanySurveyResponse =
  | ApplicationError
  | CompanySurveyListResponse;

export type ListPersonSearchResponse =
  | ApplicationError
  | PersonSearchListResponse;

export type ListPersonSourceResponse =
  | ApplicationError
  | PersonSourceListResponse;

export type ListUserResponse = ApplicationError | UserListResponse;

export type Mutation = {
  __typename: 'Mutation';
  saveCompanyFix: AddResponse;
  savePersonFix: AddResponse;
  saveSurveyResult: AddResponse;
  surveyAdd: AddResponse;
  userUpdate: AddResponse;
};

export type MutationSaveCompanyFixArgs = {
  input: FixInput;
};

export type MutationSavePersonFixArgs = {
  input: FixInput;
};

export type MutationSaveSurveyResultArgs = {
  input: CompletedSurveyInput;
};

export type MutationSurveyAddArgs = {
  input: NextSurveyInput;
};

export type MutationUserUpdateArgs = {
  input: UserInput;
};

export type NextSurveyInput = {
  practice?: InputMaybe<Scalars['Boolean']>;
};

export type NoMoreSurveysOfThisTypeResponse = {
  __typename: 'NoMoreSurveysOfThisTypeResponse';
  surveyType: Scalars['String'];
};

export type NotFoundError = GraphQlError & {
  __typename: 'NotFoundError';
  error: Scalars['Boolean'];
  message: Scalars['String'];
};

export type PersonFindInput = {
  curiePersonId: Scalars['String'];
};

export type PersonFindResponse = {
  __typename: 'PersonFindResponse';
  advisorPositions?: Maybe<Scalars['String']>;
  affinity: Scalars['String'];
  affinityPersonId?: Maybe<Scalars['String']>;
  biography?: Maybe<Scalars['String']>;
  boardPositions?: Maybe<Scalars['String']>;
  curiePersonId?: Maybe<Scalars['String']>;
  education?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employment?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  insciter: Scalars['String'];
  insciterPersonId?: Maybe<Scalars['String']>;
  personLinkedinUrl?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pitchbook: Scalars['String'];
  pitchbookPersonId?: Maybe<Scalars['String']>;
  primaryPosition?: Maybe<Scalars['String']>;
  revelio: Scalars['String'];
  revelioPersonId?: Maybe<Scalars['String']>;
  sabPositions?: Maybe<Scalars['String']>;
};

export type PersonSearchInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type PersonSearchListResponse = {
  __typename: 'PersonSearchListResponse';
  people: Array<PersonSearchResponse>;
};

export type PersonSearchResponse = {
  __typename: 'PersonSearchResponse';
  affinityPersonId?: Maybe<Scalars['String']>;
  curiePersonId?: Maybe<Scalars['String']>;
  employers: Array<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  insciterPersonId?: Maybe<Scalars['String']>;
  personLinkedinUrl?: Maybe<Scalars['String']>;
  pitchbookPersonId?: Maybe<Scalars['String']>;
  primaryPosition?: Maybe<Scalars['String']>;
  revelioPersonId?: Maybe<Scalars['String']>;
};

export type PersonSourceListResponse = {
  __typename: 'PersonSourceListResponse';
  people: Array<PersonSourceResponse>;
};

export type PersonSourceResponse = {
  __typename: 'PersonSourceResponse';
  currentInfo?: Maybe<Scalars['String']>;
  entityId: Scalars['String'];
  fullName: Scalars['String'];
  source: Scalars['String'];
};

export type PersonSourceSearchInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  source: Scalars['String'];
};

export type Query = {
  __typename: 'Query';
  companiesSearch: ListCompanySearchResponse;
  companyFind: GetCompanyFindResponse;
  companySourceSearch: ListCompanySourceResponse;
  getNextCompanySurvey: GetCompanySurveyResponse;
  listCompanySurveys: ListCompanySurveyResponse;
  listUsers: ListUserResponse;
  personFind: GetPersonFindResponse;
  personSearch: ListPersonSearchResponse;
  personSourceSearch: ListPersonSourceResponse;
  userSurveyMetrics: GetUserSurveyMetricsResponse;
};

export type QueryCompaniesSearchArgs = {
  input: CompanySearchInput;
};

export type QueryCompanyFindArgs = {
  input: CompanyFindInput;
};

export type QueryCompanySourceSearchArgs = {
  input: CompanySourceSearchInput;
};

export type QueryGetNextCompanySurveyArgs = {
  input: NextSurveyInput;
  secondIter?: Scalars['Boolean'];
};

export type QueryPersonFindArgs = {
  input: PersonFindInput;
};

export type QueryPersonSearchArgs = {
  input: PersonSearchInput;
};

export type QueryPersonSourceSearchArgs = {
  input: PersonSourceSearchInput;
};

export type QueryUserSurveyMetricsArgs = {
  input: UserSurveyMetricsInput;
};

export type SuccessResponse = GraphQlSuccess & {
  __typename: 'SuccessResponse';
  message: Scalars['String'];
};

export type User = {
  __typename: 'User';
  email?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UserListResponse = {
  __typename: 'UserListResponse';
  users: Array<User>;
};

export type UserSurveyMetricsInput = {
  practice: Scalars['Boolean'];
  surveyType: Scalars['String'];
};

export type UserSurveyMetricsResponse = {
  __typename: 'UserSurveyMetricsResponse';
  surveyCount: Scalars['Int'];
};

export type ValidationError = GraphQlError & {
  __typename: 'ValidationError';
  error: Scalars['Boolean'];
  errors: Array<FieldError>;
  message: Scalars['String'];
};

type ErrorsFragment_ApplicationError_Fragment = {
  __typename: 'ApplicationError';
  error: boolean;
  message: string;
};

type ErrorsFragment_InputError_Fragment = {
  __typename: 'InputError';
  error: boolean;
  message: string;
};

type ErrorsFragment_NotFoundError_Fragment = {
  __typename: 'NotFoundError';
  error: boolean;
  message: string;
};

type ErrorsFragment_ValidationError_Fragment = {
  __typename: 'ValidationError';
  error: boolean;
  message: string;
  errors: Array<{
    __typename: 'FieldError';
    field: string;
    message: string;
    path: string;
  }>;
};

export type ErrorsFragmentFragment =
  | ErrorsFragment_ApplicationError_Fragment
  | ErrorsFragment_InputError_Fragment
  | ErrorsFragment_NotFoundError_Fragment
  | ErrorsFragment_ValidationError_Fragment;

export type SaveCompanyFixMutationVariables = Exact<{
  input: FixInput;
}>;

export type SaveCompanyFixMutation = {
  __typename: 'Mutation';
  saveCompanyFix:
    | { __typename: 'ApplicationError' }
    | { __typename: 'InputError' }
    | { __typename: 'NotFoundError' }
    | { __typename: 'SuccessResponse'; message: string }
    | { __typename: 'ValidationError' };
};

export type SavePersonFixMutationVariables = Exact<{
  input: FixInput;
}>;

export type SavePersonFixMutation = {
  __typename: 'Mutation';
  savePersonFix:
    | { __typename: 'ApplicationError' }
    | { __typename: 'InputError' }
    | { __typename: 'NotFoundError' }
    | { __typename: 'SuccessResponse'; message: string }
    | { __typename: 'ValidationError' };
};

export type SaveSurveyResultMutationVariables = Exact<{
  input: CompletedSurveyInput;
}>;

export type SaveSurveyResultMutation = {
  __typename: 'Mutation';
  saveSurveyResult:
    | { __typename: 'ApplicationError' }
    | { __typename: 'InputError' }
    | { __typename: 'NotFoundError' }
    | { __typename: 'SuccessResponse'; message: string }
    | { __typename: 'ValidationError' };
};

export type SurveyAddMutationVariables = Exact<{
  input: NextSurveyInput;
}>;

export type SurveyAddMutation = {
  __typename: 'Mutation';
  surveyAdd:
    | { __typename: 'ApplicationError' }
    | { __typename: 'InputError' }
    | { __typename: 'NotFoundError' }
    | { __typename: 'SuccessResponse'; message: string }
    | { __typename: 'ValidationError' };
};

export type UserUpdateMutationVariables = Exact<{
  input: UserInput;
}>;

export type UserUpdateMutation = {
  __typename: 'Mutation';
  userUpdate:
    | { __typename: 'ApplicationError' }
    | { __typename: 'InputError' }
    | { __typename: 'NotFoundError' }
    | { __typename: 'SuccessResponse'; message: string }
    | { __typename: 'ValidationError' };
};

export type CompaniesSearchQueryVariables = Exact<{
  input: CompanySearchInput;
}>;

export type CompaniesSearchQuery = {
  __typename: 'Query';
  companiesSearch:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'CompanySearchListResponse';
        companies: Array<{
          __typename: 'CompanySearchResponse';
          curieOrgId?: string | null;
          companyName?: string | null;
          pitchbookOrgId?: string | null;
          revelioOrgId?: string | null;
          globalDataCompanyId?: string | null;
          insciterCompanyId?: string | null;
          insciterInstituteId?: string | null;
          affinityOrgId?: string | null;
          publicPharmaOrgId?: string | null;
          manualInstituteId?: string | null;
          isCompany: boolean;
          isInvestor: boolean;
          isInstitute: boolean;
          website?: string | null;
        }>;
      };
};

export type CompanyFindQueryVariables = Exact<{
  input: CompanyFindInput;
}>;

export type CompanyFindQuery = {
  __typename: 'Query';
  companyFind:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'CompanyFindResponse';
        curieOrgId?: string | null;
        companyName?: string | null;
        pitchbookOrgId?: string | null;
        revelioOrgId?: string | null;
        globalDataCompanyId?: string | null;
        insciterCompanyId?: string | null;
        insciterInstituteId?: string | null;
        affinityOrgId?: string | null;
        publicPharmaOrgId?: string | null;
        manualInstituteId?: string | null;
        companyLinkedinUrl?: string | null;
        website?: string | null;
        isCompany: boolean;
        isInvestor: boolean;
        isInstitute: boolean;
        isAcademic: boolean;
        businessStatus?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        pitchbookCompany: string;
        pitchbookInvestor: string;
        pitchbookInstitute: string;
        pitchbookOtherOrg: string;
        investorsBronze: string;
        revelioCompany: string;
        revelioInstitute: string;
        publicPharma: string;
        globalData: string;
        manualInstitute: string;
        insciterInstitute: string;
        insciterCompany: string;
        affinityCompany: string;
      }
    | { __typename: 'NotFoundError'; error: boolean; message: string };
};

export type CompanySourceSearchQueryVariables = Exact<{
  input: CompanySourceSearchInput;
}>;

export type CompanySourceSearchQuery = {
  __typename: 'Query';
  companySourceSearch:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'CompanySourceListResponse';
        companies: Array<{
          __typename: 'CompanySourceResponse';
          source: string;
          entityId: string;
          companyName: string;
          website?: string | null;
          companyLinkedinUrl?: string | null;
        }>;
      };
};

export type GetNextCompanySurveyQueryVariables = Exact<{
  input: NextSurveyInput;
}>;

export type GetNextCompanySurveyQuery = {
  __typename: 'Query';
  getNextCompanySurvey:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'CompanySurveyResponse';
        matchId: string;
        companyA: {
          __typename: 'Company';
          entityId: string;
          source: string;
          name: string;
          website?: string | null;
          linkedinUrl?: string | null;
          bio?: string | null;
          hqLocation?: string | null;
        };
        companyB: {
          __typename: 'Company';
          entityId: string;
          source: string;
          name: string;
          website?: string | null;
          linkedinUrl?: string | null;
          bio?: string | null;
          hqLocation?: string | null;
        };
      }
    | { __typename: 'NoMoreSurveysOfThisTypeResponse'; surveyType: string }
    | { __typename: 'NotFoundError'; error: boolean; message: string };
};

export type ListCompanySurveysQueryVariables = Exact<{ [key: string]: never }>;

export type ListCompanySurveysQuery = {
  __typename: 'Query';
  listCompanySurveys:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'CompanySurveyListResponse';
        surveys: Array<{
          __typename: 'CompanySurveyResponse';
          companyA: {
            __typename: 'Company';
            name: string;
            website?: string | null;
            linkedinUrl?: string | null;
          };
          companyB: {
            __typename: 'Company';
            name: string;
            website?: string | null;
            linkedinUrl?: string | null;
          };
        }>;
      };
};

export type ListUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListUsersQuery = {
  __typename: 'Query';
  listUsers:
    | { __typename: 'ApplicationError' }
    | {
        __typename: 'UserListResponse';
        users: Array<{
          __typename: 'User';
          email?: string | null;
          name?: string | null;
          isAdmin?: boolean | null;
          isActive?: boolean | null;
        }>;
      };
};

export type PersonFindQueryVariables = Exact<{
  input: PersonFindInput;
}>;

export type PersonFindQuery = {
  __typename: 'Query';
  personFind:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | { __typename: 'NotFoundError'; error: boolean; message: string }
    | {
        __typename: 'PersonFindResponse';
        curiePersonId?: string | null;
        fullName?: string | null;
        revelioPersonId?: string | null;
        pitchbookPersonId?: string | null;
        insciterPersonId?: string | null;
        affinityPersonId?: string | null;
        primaryPosition?: string | null;
        personLinkedinUrl?: string | null;
        email?: string | null;
        phone?: string | null;
        biography?: string | null;
        employment?: string | null;
        education?: string | null;
        boardPositions?: string | null;
        advisorPositions?: string | null;
        sabPositions?: string | null;
        pitchbook: string;
        revelio: string;
        insciter: string;
        affinity: string;
      };
};

export type PersonSearchQueryVariables = Exact<{
  input: PersonSearchInput;
}>;

export type PersonSearchQuery = {
  __typename: 'Query';
  personSearch:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'PersonSearchListResponse';
        people: Array<{
          __typename: 'PersonSearchResponse';
          curiePersonId?: string | null;
          fullName?: string | null;
          revelioPersonId?: string | null;
          pitchbookPersonId?: string | null;
          insciterPersonId?: string | null;
          affinityPersonId?: string | null;
          primaryPosition?: string | null;
          personLinkedinUrl?: string | null;
          employers: Array<string>;
        }>;
      };
};

export type PersonSourceSearchQueryVariables = Exact<{
  input: PersonSourceSearchInput;
}>;

export type PersonSourceSearchQuery = {
  __typename: 'Query';
  personSourceSearch:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | {
        __typename: 'PersonSourceListResponse';
        people: Array<{
          __typename: 'PersonSourceResponse';
          source: string;
          entityId: string;
          fullName: string;
          currentInfo?: string | null;
        }>;
      };
};

export type UserSurveyMetricsQueryVariables = Exact<{
  input: UserSurveyMetricsInput;
}>;

export type UserSurveyMetricsQuery = {
  __typename: 'Query';
  userSurveyMetrics:
    | { __typename: 'ApplicationError'; error: boolean; message: string }
    | { __typename: 'NotFoundError'; error: boolean; message: string }
    | { __typename: 'UserSurveyMetricsResponse'; surveyCount: number };
};

export const namedOperations = {
  Query: {
    companiesSearch: 'companiesSearch',
    companyFind: 'companyFind',
    companySourceSearch: 'companySourceSearch',
    getNextCompanySurvey: 'getNextCompanySurvey',
    listCompanySurveys: 'listCompanySurveys',
    listUsers: 'listUsers',
    personFind: 'personFind',
    personSearch: 'personSearch',
    personSourceSearch: 'personSourceSearch',
    userSurveyMetrics: 'userSurveyMetrics',
  },
  Mutation: {
    saveCompanyFix: 'saveCompanyFix',
    savePersonFix: 'savePersonFix',
    saveSurveyResult: 'saveSurveyResult',
    surveyAdd: 'surveyAdd',
    userUpdate: 'userUpdate',
  },
  Fragment: {
    ErrorsFragment: 'ErrorsFragment',
    SuccessResponseFragment: 'SuccessResponseFragment',
  },
};
export const ErrorsFragmentFragmentDoc = gql`
  fragment ErrorsFragment on GraphQLError {
    __typename
    error
    message
    ... on ValidationError {
      errors {
        field
        message
        path
      }
    }
  }
`;
export const SuccessResponseFragmentFragmentDoc = gql`
  fragment SuccessResponseFragment on GraphQLSuccess {
    __typename
    message
  }
`;
export const SaveCompanyFixDocument = gql`
  mutation saveCompanyFix($input: FixInput!) {
    saveCompanyFix(input: $input) {
      __typename
      ... on SuccessResponse {
        message
      }
    }
  }
`;
export const SavePersonFixDocument = gql`
  mutation savePersonFix($input: FixInput!) {
    savePersonFix(input: $input) {
      __typename
      ... on SuccessResponse {
        message
      }
    }
  }
`;
export const SaveSurveyResultDocument = gql`
  mutation saveSurveyResult($input: CompletedSurveyInput!) {
    saveSurveyResult(input: $input) {
      ... on SuccessResponse {
        message
      }
    }
  }
`;
export const SurveyAddDocument = gql`
  mutation surveyAdd($input: NextSurveyInput!) {
    surveyAdd(input: $input) {
      ... on SuccessResponse {
        message
      }
    }
  }
`;
export const UserUpdateDocument = gql`
  mutation userUpdate($input: UserInput!) {
    userUpdate(input: $input) {
      __typename
      ... on SuccessResponse {
        message
      }
    }
  }
`;
export const CompaniesSearchDocument = gql`
  query companiesSearch($input: CompanySearchInput!) {
    companiesSearch(input: $input) {
      __typename
      ... on CompanySearchListResponse {
        companies {
          curieOrgId
          companyName
          pitchbookOrgId
          revelioOrgId
          globalDataCompanyId
          insciterCompanyId
          insciterInstituteId
          affinityOrgId
          publicPharmaOrgId
          manualInstituteId
          isCompany
          isInvestor
          isInstitute
          website
        }
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const CompanyFindDocument = gql`
  query companyFind($input: CompanyFindInput!) {
    companyFind(input: $input) {
      __typename
      ... on CompanyFindResponse {
        curieOrgId
        companyName
        pitchbookOrgId
        revelioOrgId
        globalDataCompanyId
        insciterCompanyId
        insciterInstituteId
        affinityOrgId
        publicPharmaOrgId
        manualInstituteId
        companyLinkedinUrl
        website
        isCompany
        isInvestor
        isInstitute
        isAcademic
        businessStatus
        city
        state
        country
        pitchbookCompany
        pitchbookInvestor
        pitchbookInstitute
        pitchbookOtherOrg
        investorsBronze
        revelioCompany
        revelioInstitute
        publicPharma
        globalData
        manualInstitute
        insciterInstitute
        insciterCompany
        affinityCompany
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const CompanySourceSearchDocument = gql`
  query companySourceSearch($input: CompanySourceSearchInput!) {
    companySourceSearch(input: $input) {
      __typename
      ... on CompanySourceListResponse {
        companies {
          source
          entityId
          companyName
          website
          companyLinkedinUrl
        }
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const GetNextCompanySurveyDocument = gql`
  query getNextCompanySurvey($input: NextSurveyInput!) {
    getNextCompanySurvey(input: $input) {
      __typename
      ... on CompanySurveyResponse {
        matchId
        companyA {
          entityId
          source
          name
          website
          linkedinUrl
          bio
          hqLocation
        }
        companyB {
          entityId
          source
          name
          website
          linkedinUrl
          bio
          hqLocation
        }
      }
      ... on NoMoreSurveysOfThisTypeResponse {
        surveyType
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const ListCompanySurveysDocument = gql`
  query listCompanySurveys {
    listCompanySurveys {
      __typename
      ... on CompanySurveyListResponse {
        surveys {
          companyA {
            name
            website
            linkedinUrl
          }
          companyB {
            name
            website
            linkedinUrl
          }
        }
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const ListUsersDocument = gql`
  query listUsers {
    listUsers {
      __typename
      ... on UserListResponse {
        users {
          email
          name
          isAdmin
          isActive
        }
      }
    }
  }
`;
export const PersonFindDocument = gql`
  query personFind($input: PersonFindInput!) {
    personFind(input: $input) {
      __typename
      ... on PersonFindResponse {
        curiePersonId
        fullName
        revelioPersonId
        pitchbookPersonId
        insciterPersonId
        affinityPersonId
        primaryPosition
        personLinkedinUrl
        email
        phone
        biography
        employment
        education
        boardPositions
        advisorPositions
        sabPositions
        pitchbook
        revelio
        insciter
        affinity
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const PersonSearchDocument = gql`
  query personSearch($input: PersonSearchInput!) {
    personSearch(input: $input) {
      __typename
      ... on PersonSearchListResponse {
        people {
          curiePersonId
          fullName
          revelioPersonId
          pitchbookPersonId
          insciterPersonId
          affinityPersonId
          primaryPosition
          personLinkedinUrl
          employers
        }
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const PersonSourceSearchDocument = gql`
  query personSourceSearch($input: PersonSourceSearchInput!) {
    personSourceSearch(input: $input) {
      __typename
      ... on PersonSourceListResponse {
        people {
          source
          entityId
          fullName
          currentInfo
        }
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;
export const UserSurveyMetricsDocument = gql`
  query userSurveyMetrics($input: UserSurveyMetricsInput!) {
    userSurveyMetrics(input: $input) {
      __typename
      ... on UserSurveyMetricsResponse {
        surveyCount
      }
      ...ErrorsFragment
    }
  }
  ${ErrorsFragmentFragmentDoc}
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    saveCompanyFix(
      variables: SaveCompanyFixMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SaveCompanyFixMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SaveCompanyFixMutation>(
            SaveCompanyFixDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'saveCompanyFix',
        'mutation'
      );
    },
    savePersonFix(
      variables: SavePersonFixMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SavePersonFixMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SavePersonFixMutation>(
            SavePersonFixDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'savePersonFix',
        'mutation'
      );
    },
    saveSurveyResult(
      variables: SaveSurveyResultMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SaveSurveyResultMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SaveSurveyResultMutation>(
            SaveSurveyResultDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'saveSurveyResult',
        'mutation'
      );
    },
    surveyAdd(
      variables: SurveyAddMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SurveyAddMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SurveyAddMutation>(SurveyAddDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'surveyAdd',
        'mutation'
      );
    },
    userUpdate(
      variables: UserUpdateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserUpdateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserUpdateMutation>(UserUpdateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'userUpdate',
        'mutation'
      );
    },
    companiesSearch(
      variables: CompaniesSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CompaniesSearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CompaniesSearchQuery>(
            CompaniesSearchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'companiesSearch',
        'query'
      );
    },
    companyFind(
      variables: CompanyFindQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CompanyFindQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CompanyFindQuery>(CompanyFindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'companyFind',
        'query'
      );
    },
    companySourceSearch(
      variables: CompanySourceSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CompanySourceSearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CompanySourceSearchQuery>(
            CompanySourceSearchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'companySourceSearch',
        'query'
      );
    },
    getNextCompanySurvey(
      variables: GetNextCompanySurveyQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<GetNextCompanySurveyQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetNextCompanySurveyQuery>(
            GetNextCompanySurveyDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'getNextCompanySurvey',
        'query'
      );
    },
    listCompanySurveys(
      variables?: ListCompanySurveysQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ListCompanySurveysQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListCompanySurveysQuery>(
            ListCompanySurveysDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'listCompanySurveys',
        'query'
      );
    },
    listUsers(
      variables?: ListUsersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<ListUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListUsersQuery>(ListUsersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'listUsers',
        'query'
      );
    },
    personFind(
      variables: PersonFindQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PersonFindQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PersonFindQuery>(PersonFindDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'personFind',
        'query'
      );
    },
    personSearch(
      variables: PersonSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PersonSearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PersonSearchQuery>(PersonSearchDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'personSearch',
        'query'
      );
    },
    personSourceSearch(
      variables: PersonSourceSearchQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PersonSourceSearchQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PersonSourceSearchQuery>(
            PersonSourceSearchDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'personSourceSearch',
        'query'
      );
    },
    userSurveyMetrics(
      variables: UserSurveyMetricsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserSurveyMetricsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserSurveyMetricsQuery>(
            UserSurveyMetricsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'userSurveyMetrics',
        'query'
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export function getSdkWithHooks(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  const sdk = getSdk(client, withWrapper);
  return {
    ...sdk,
    useCompaniesSearch(
      key: SWRKeyInterface,
      variables: CompaniesSearchQueryVariables,
      config?: SWRConfigInterface<CompaniesSearchQuery, ClientError>
    ) {
      return useSWR<CompaniesSearchQuery, ClientError>(
        key,
        () => sdk.companiesSearch(variables),
        config
      );
    },
    useCompanyFind(
      key: SWRKeyInterface,
      variables: CompanyFindQueryVariables,
      config?: SWRConfigInterface<CompanyFindQuery, ClientError>
    ) {
      return useSWR<CompanyFindQuery, ClientError>(
        key,
        () => sdk.companyFind(variables),
        config
      );
    },
    useCompanySourceSearch(
      key: SWRKeyInterface,
      variables: CompanySourceSearchQueryVariables,
      config?: SWRConfigInterface<CompanySourceSearchQuery, ClientError>
    ) {
      return useSWR<CompanySourceSearchQuery, ClientError>(
        key,
        () => sdk.companySourceSearch(variables),
        config
      );
    },
    useGetNextCompanySurvey(
      key: SWRKeyInterface,
      variables: GetNextCompanySurveyQueryVariables,
      config?: SWRConfigInterface<GetNextCompanySurveyQuery, ClientError>
    ) {
      return useSWR<GetNextCompanySurveyQuery, ClientError>(
        key,
        () => sdk.getNextCompanySurvey(variables),
        config
      );
    },
    useListCompanySurveys(
      key: SWRKeyInterface,
      variables?: ListCompanySurveysQueryVariables,
      config?: SWRConfigInterface<ListCompanySurveysQuery, ClientError>
    ) {
      return useSWR<ListCompanySurveysQuery, ClientError>(
        key,
        () => sdk.listCompanySurveys(variables),
        config
      );
    },
    useListUsers(
      key: SWRKeyInterface,
      variables?: ListUsersQueryVariables,
      config?: SWRConfigInterface<ListUsersQuery, ClientError>
    ) {
      return useSWR<ListUsersQuery, ClientError>(
        key,
        () => sdk.listUsers(variables),
        config
      );
    },
    usePersonFind(
      key: SWRKeyInterface,
      variables: PersonFindQueryVariables,
      config?: SWRConfigInterface<PersonFindQuery, ClientError>
    ) {
      return useSWR<PersonFindQuery, ClientError>(
        key,
        () => sdk.personFind(variables),
        config
      );
    },
    usePersonSearch(
      key: SWRKeyInterface,
      variables: PersonSearchQueryVariables,
      config?: SWRConfigInterface<PersonSearchQuery, ClientError>
    ) {
      return useSWR<PersonSearchQuery, ClientError>(
        key,
        () => sdk.personSearch(variables),
        config
      );
    },
    usePersonSourceSearch(
      key: SWRKeyInterface,
      variables: PersonSourceSearchQueryVariables,
      config?: SWRConfigInterface<PersonSourceSearchQuery, ClientError>
    ) {
      return useSWR<PersonSourceSearchQuery, ClientError>(
        key,
        () => sdk.personSourceSearch(variables),
        config
      );
    },
    useUserSurveyMetrics(
      key: SWRKeyInterface,
      variables: UserSurveyMetricsQueryVariables,
      config?: SWRConfigInterface<UserSurveyMetricsQuery, ClientError>
    ) {
      return useSWR<UserSurveyMetricsQuery, ClientError>(
        key,
        () => sdk.userSurveyMetrics(variables),
        config
      );
    },
  };
}
export type SdkWithHooks = ReturnType<typeof getSdkWithHooks>;
