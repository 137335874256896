import React, { Component } from 'react';
import { Button, Typography } from '@mui/material';
import { BoxCentered, ContainerViewport, Link } from 'mui/components';
import { formatError, FormattedError } from 'mui/model';

export interface FallbackProps {
  image?: string;
  formattedError: FormattedError;
}

interface BoundaryProps {
  children?: React.ReactNode;
  Fallback: React.ComponentType<FallbackProps>;
}

interface BoundaryState {
  error: Error | null;
}

export function ErrorText({ formattedError }: FallbackProps) {
  return (
    <BoxCentered
      sx={(theme) => ({
        minHeight: 'unset',
        typography: 'body1',
        color: theme.palette.text.secondary,
        p: 2,
      })}
      data-test="error-message"
    >
      {formattedError.displayMessage}
    </BoxCentered>
  );
}

/**
 * Error Fallback: Boxed Container
 **/
export function ErrorBox({ image, formattedError }: FallbackProps) {
  return (
    <BoxCentered
      sx={{ minHeight: 'unset', flexDirection: 'column' }}
      data-test="error-box"
    >
      <img
        src={image || '/static/mui/images/error-generic.png'}
        alt="Generic Error"
        style={{ width: '80%', maxWidth: '300px' }}
      />
      <Typography variant="h5" data-test="error-title">
        {formattedError.displayTitle}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        mt={1}
        data-test="error-message"
      >
        {formattedError.displayMessage}
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={3}>
        <Typography color="primary" component="span">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={() => window.location.reload()} to="#">
            Reload
          </Link>
        </Typography>
        &nbsp;the page to try again
      </Typography>
      <Typography variant="body1" color="text.secondary" mb={1}>
        or feel free to...
      </Typography>
      <Button variant="outlined" href="mailto:data-validation@curie.bio">
        Contact Support
      </Button>
    </BoxCentered>
  );
}

/**
 * Error Fallback: Layout / Page.
 **/
export function ErrorPage(props: FallbackProps) {
  return (
    <ContainerViewport data-test="error-page">
      <ErrorBox {...props} />
    </ContainerViewport>
  );
}

/**
 * An implementation of an Error Boundary.
 * ref: https://reactjs.org/docs/error-boundaries.html
 **/
export class ErrorBoundary extends Component<BoundaryProps, BoundaryState> {
  public state: BoundaryState = {
    error: null,
  };

  public static getDerivedStateFromError(error: Error): BoundaryState {
    return {
      error,
    };
  }

  // @TODO: Do we want formatted sentry reporting/capture?
  // public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
  // }

  render() {
    const { children, Fallback } = this.props;
    const { error } = this.state;

    if (error !== null) {
      return <Fallback formattedError={formatError(error)} />;
    }
    return <>{children}</>;
  }
}
