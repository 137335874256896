import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
  tabClasses,
  tabsClasses
} from '@mui/material';

import { alpha } from '@mui/material/styles';
import { Link } from 'mui/components';
import { useAPI } from 'hooks';

const NAV_PAGES = [
  {
    title: 'Home',
    url: '/',
    paths: ['/home'],
    isActive: false,
    isAdmin: false
  },
  {
    title: 'Surveys',
    url: '/surveys',
    paths: ['/surveys'],
    isActive: true,
    isAdmin: false
  },
  {
    title: 'Practice',
    url: '/practice',
    paths: ['/practice'],
    isActive: false,
    isAdmin: false
  },
  {
    title: 'Search',
    url: '/search',
    paths: ['/search'],
    isActive: false,
    isAdmin: true
  },
  {
    title: 'Admin',
    url: '/admin',
    paths: ['/admin'],
    isActive: false,
    isAdmin: true
  },
];

/**
 * Render the navigation menu with activity behavior.
 **/
function Nav({
  activePage,
  isAdmin = false,
  isActive = false,
}: {
  activePage: string;
  isAdmin?: boolean;
  isActive?: boolean;
}) {
  const activeTab =
    NAV_PAGES.find((p) => p.title === activePage)?.title ?? false;
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 0,
        flexDirection: 'row',
        gap: 3,
        height: 64,
      }}
      data-test="header-navigation"
    >
      <Link to="/" sx={{ display: 'flex' }} data-test="header-logo">
        <img
          src="/static/mui/images/logo-header.svg"
          alt="Logo"
          style={{ width: '30px' }}
        />
      </Link>
      <Tabs
        value={activeTab}
        sx={{ [`.${tabsClasses.flexContainer}`]: { height: '100%' } }}
      >
        {NAV_PAGES.map((p) => {
          if (p.isActive && !isActive) return null;
          if (p.isAdmin && !isAdmin) return null;
          return (
            <Tab
              component={Link}
              to={p.url}
              key={p.url}
              label={p.title}
              sx={(theme) => ({
                color: alpha(theme.palette.common.white, 0.7),
                [`&.${tabClasses.selected}`]: {
                  color: theme.palette.primary.light,
                },
              })}
              value={p.title}
              data-test="header-navigation-link"
            />
        )})}
      </Tabs>
    </Box>
  );
}

export function Header() {
  const location = useLocation();

  const [activePage, setActivePage] = useState<string>('');

  useEffect(() => {
    const page =
      location.pathname === '/'
        ? NAV_PAGES[0].title
        : NAV_PAGES.find((p) =>
            p.paths.some((path) => location.pathname.startsWith(path))
          )?.title ?? location.pathname;

    setActivePage(page);
  }, [location]);

  const { apiHttp } = useAPI();
  const { data: user } = apiHttp.useAuthUser()

  return (
    <AppBar position="relative" component="nav" color="primary">
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}
      >
        <Nav activePage={activePage} isAdmin={user?.is_admin} isActive={user?.is_active || false} />

      </Toolbar>
    </AppBar>
  );
}
