import React from 'react';
import { SWRConfig } from 'swr';
import { useLocation, Outlet } from 'react-router-dom';
import { GlobalStyles } from '@mui/material';
import { Header } from '../Header';
import { ErrorBoundary, ErrorPage, Sus } from 'mui/components';
import { errorResponseMiddleware } from 'hooks';

export function DefaultLayout() {
  const location = useLocation();

  return (
    // @ts-ignore
    <SWRConfig value={{ suspense: true, use: [errorResponseMiddleware] }}>
      <GlobalStyles
        styles={(theme) => ({
          body: {
            overflow: 'hidden',
            fontSize: 14,
          },
          html: {
            fontSize: 10,
            '-webkit-font-smoothing': 'auto',
          },
          '#content': {
            height: '100vh',
            backgroundColor: theme.palette.background.default,
          },
        })}
      />
      <ErrorBoundary Fallback={ErrorPage}>
        <Header />
        <ErrorBoundary
          Fallback={ErrorPage}
          key={`${location.pathname}-${location.search}`}
        >
          <Sus>
            <Outlet />
          </Sus>
        </ErrorBoundary>
      </ErrorBoundary>
    </SWRConfig>
  );
}
