import axios from 'axios';
import useSWR from 'swr';

const DEFAULT_INIT = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true
};

// We can unify this in methods with default processing.

export const httpGet = async <T>(
  path: string,
  init?: RequestInit
): Promise<T> => {
  const response = await axios.get(path,
    DEFAULT_INIT
  )

  if (!response.data) {
    // Caught and returned by useSWR
    throw Error('Unexpected data fetch error');
  }

  return response.data;
};

interface UserResponse {
  name: string | null;
  email: string | null;
  is_admin: boolean;
  is_active: boolean;
}

export const getHttpSdk = () => {
  return {
    useAuthUser: <Data = UserResponse, Error = any>() => {
      return useSWR<Data, Error>('/api/auth/user', httpGet, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        dedupingInterval: 3600000, // 1 hour
      });
    }
  };
};

export type SdkHttpAPI = ReturnType<typeof getHttpSdk>;
