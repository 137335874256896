import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Suspense,
} from 'react';
import { Box, CircularProgress } from '@mui/material';
/**
 * Pseudo component to maintain suspense triggered for
 * an artificial delay.
 **/
function SuspenseLock() {
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw new Promise(() => {});
  return <></>;
}

/**
 * Display a fallback only after an initial
 * delay has passed.
 **/
function DelayedFallback({
  fallback,
  onShow,
  showAfterMs = 300,
}: {
  fallback: React.ReactNode;
  onShow: () => void;
  showAfterMs?: number;
}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (showAfterMs > 0) {
      const showFallbackLock = setTimeout(() => {
        setShow(true);
        onShow();
      }, showAfterMs);

      return () => {
        clearTimeout(showFallbackLock);
      };
    }

    // Show immediately
    setShow(true);
    onShow();
  }, [showAfterMs, onShow]);

  return show ? <>{fallback}</> : null;
}

export function DefaultLoader() {
  return (
    <Box
      data-test="default-loader"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 2.5,
        height: 'fit-content',
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
}

/**
 * Configurable Suspense:
 * In addition to the existing suspense API:
 * * Specify a duration before the loader is actually rendered.
 * * Specify a minimal duration to keep the loader on screen.
 **/
export function Sus({
  children,
  fallback = <DefaultLoader />,
  showAfterMs = 300,
  minimumOnScreenMs = 500,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  showAfterMs?: number;
  minimumOnScreenMs?: number;
}) {
  const [show, setShow] = useState<boolean>(false);

  const enforceMinimumLock = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onShow = useCallback(() => {
    setShow(true);

    if (enforceMinimumLock.current !== null) {
      // Another promise completed, do not release.
      clearTimeout(enforceMinimumLock.current);
    }

    enforceMinimumLock.current = setTimeout(() => {
      setShow(false);
    }, minimumOnScreenMs);
  }, [minimumOnScreenMs]);

  useEffect(() => {
    // Cleanup.
    return () => {
      if (enforceMinimumLock.current !== null) {
        clearTimeout(enforceMinimumLock.current);
      }
    };
  }, []);

  return (
    <Suspense
      fallback={
        <DelayedFallback
          fallback={fallback}
          showAfterMs={showAfterMs}
          onShow={onShow}
        />
      }
    >
      {show && <SuspenseLock />}
      {children}
    </Suspense>
  );
}
