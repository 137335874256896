import React, { useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ExternalLink } from 'mui/components';
import { CompanySurveyResponse, Company, UserSurveyMetricsResponse } from 'api/graphql';
import { useAPI } from 'hooks';

type ManualCompany = {
  notEnoughInfo: boolean;
  name: string;
  website: string;
  linkedinUrl: string;
  city: string;
  state: string;
  country: string;
}

const EMPTY_COMPANY: ManualCompany = {
  notEnoughInfo: false,
  name: '',
  website: '',
  linkedinUrl: '',
  city: '',
  state: '',
  country: '',
}

export type SubmitData = {
  companiesMatch: string;
  mismatchReasons: string[];
  companyAType: string;
  companyBType: string;
  companyANoLongerExists: boolean;
  companyBNoLongerExists: boolean;
  correctCompanyA: ManualCompany;
  correctCompanyB: ManualCompany;
  startTime: number;
  endTime: number;
}


const now = () => {
  return Math.round((new Date()).getTime() / 1000);
}

function MisMatchReasonCard({
  company,
  includeSubsidiary,
  onMismatchReasonChange
}: {
  company: Company;
  includeSubsidiary: boolean;
  onMismatchReasonChange: (mismatchReasons: string[]) => void
}) {

  const [incorrectReasons, setIncorrectReasons] = useState<string[]>([]);
  const [selectedParent, setSelectedParent] = React.useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    const fieldName = (event.target as any).name as string;
    if (incorrectReasons.includes(fieldName)) {
      setIncorrectReasons([...incorrectReasons].filter(i => i !== fieldName));
    }
    else {
      setIncorrectReasons([...incorrectReasons, fieldName])
    }
  };

  useEffect(() => {
    onMismatchReasonChange(incorrectReasons);
  }, [incorrectReasons])


  useEffect(() => {
    setIncorrectReasons([])
  }, [company])

  return (
    <Card>
      <CardHeader
        title="How do you know that?"
        sx={{ py: 1 }}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
      <CardContent sx={{ mx: 2, py: 1 }}>
        <FormGroup>
          {includeSubsidiary && (
            <>
              <FormControlLabel name="oneIsSubsidiary" control={<Checkbox />} label="One is a subsidiary of the other" onChange={handleChange} />
              {incorrectReasons.includes('oneIsSubsidiary') && (
                <>
                  <InputLabel sx={{mt: 1}}>Select the parent company</InputLabel>
                  <Select
                    labelId="select-parent-label"
                    id="select-parent"
                    value={selectedParent}
                    label="Select the parent company"
                    onChange={(e) => {
                      const newReasons = [...incorrectReasons];
                      const newFilterd = newReasons.filter(r => !r.startsWith('parent:'));
                      newFilterd.push(`parent:${e.target.value}`)
                      setIncorrectReasons(newFilterd);
                      setSelectedParent(e.target.value as string)
                    }}
                  >
                    <MenuItem value="company_1">Company 1</MenuItem>
                    <MenuItem value="company_2">Company 2</MenuItem>
                  </Select>
                </>
              )}
            </>
          )}
          <TextField
            id="mismatch-reason-input"
            label="Explain and provide supporting evidence"
            variant="outlined"
            placeholder='Please provide supporting evidence such as URLs and notes to support your decision.'
            multiline
            rows={4}
            sx={{mt: 2}}
            onChange={(e) => {
              const newReasons = [...incorrectReasons];
              const newFilterd = newReasons.filter(r => !r.startsWith('other:'));
              newFilterd.push(`other:${e.target.value}`)
              setIncorrectReasons(newFilterd);
            }}
          />
        </FormGroup>
      </CardContent>
    </Card>
  )
}


function CompanyNoLongerExistsCard({
  company,
  title,
  onChange
}: {
  company: Company;
  title: string;
  onChange: (exists: boolean) => void
}) {

  const [noLongerExists, setNoLongerExists] = useState<boolean>(false);

  useEffect(() => {
    setNoLongerExists(false);
  }, [company])

  useEffect(() => {
    onChange(noLongerExists);
  }, [noLongerExists])

  return (
    <Card>
      <CardContent sx={{ mx: 2, my: 2 }}>
        <FormGroup>
          <FormControlLabel
            name="noLongerExists"
            control={<Checkbox checked={noLongerExists} />}
            label={title}
            onChange={() => setNoLongerExists(!noLongerExists)}
          />
        </FormGroup>
      </CardContent>
    </Card>
  )
}

function SelectCompanyType({
  company,
  onCompanyTypeChange,
  title
}: {
  company: Company;
  onCompanyTypeChange: (companyType: string) => void;
  title: string;
}) {
  const [selectedType, setSelectedType] = React.useState<string | null>(null);

  useEffect(() => {
    setSelectedType(null)
  }, [company])

  useEffect(() => {
    onCompanyTypeChange(selectedType || '');
  }, [selectedType]);

  return (
    <Card>
    <CardHeader
      title={(
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="caption">
            <strong>Company</strong>: sells - or planns to sell - a good or service
          </Typography>
          <Typography variant="caption">
            <strong>Institute</strong>: an organization that is a hospital or a university or research center
          </Typography>
          <Typography variant="caption">
            <strong>Investor</strong>: invests capital into companies, e.g Venture Capital
          </Typography>
          <Typography variant="caption">
            <strong>Other</strong>: includes medical centers/clinics, or it doesn't fit into one of the others
          </Typography>
        </Stack>
      )}
    />
    <CardContent sx={{ py: 1 }}>
      <FormControl fullWidth>
        <InputLabel sx={{background: 'white'}}>Select the type of organization</InputLabel>
        <Select
          labelId="select-org-label"
          id="select-org-type"
          value={selectedType}
          label="Select the org type"
          onChange={(e) => {
            setSelectedType(e.target.value as string);
          }}
        >
          <MenuItem value="company">Company</MenuItem>
          <MenuItem value="institute">Institute</MenuItem>
          <MenuItem value="investor">Investor</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
    </CardContent>
  </Card>
  )
}

function CreateCorrectProfileCard({
  company,
  companyNumber,
  onCorrectCompanyChange
}: {
  company: Company;
  companyNumber: number;
  onCorrectCompanyChange: (correctCompany: ManualCompany) => void;
}) {

  const [manualCompany, setManualCompany] = useState<ManualCompany>({ ...EMPTY_COMPANY })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    const fieldName = (event.target as any).id as string;
    const newCompany = { ...manualCompany };
    if (fieldName === 'company-name-input') {
      newCompany.name = targetValue;
    }
    else if (fieldName === 'company-website-input') {
      newCompany.website = targetValue;
    }
    else if (fieldName === 'company-linkedin-input') {
      newCompany.linkedinUrl = targetValue;
    }
    else if (fieldName === 'company-city-input') {
      newCompany.city = targetValue;
    }
    else if (fieldName === 'company-state-input') {
      newCompany.state = targetValue;
    }
    else if (fieldName === 'company-country-input') {
      newCompany.country = targetValue;
    }
    setManualCompany(newCompany);
  }

  useEffect(() => {
    onCorrectCompanyChange(manualCompany);
  }, [manualCompany])

  useEffect(() => {
    setManualCompany({ ...EMPTY_COMPANY })
  }, [company])

  return (
    <Card>
      <CardHeader
        title={`Update / add values to build the correct profile for Organization ${companyNumber} "${company.name}"?`}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
      <CardContent sx={{ m: 2, mt: 0 }}>
        <Typography variant='caption'>
          If you cannot find a website and linkedin url click 'Not enough info'
        </Typography>
        <FormGroup>
          <Stack direction="column" spacing={1}>
            <FormControlLabel
              label="Not enough info to determine"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const newCompany = { ...manualCompany };
                newCompany.notEnoughInfo = e.target.checked as boolean;
                setManualCompany(newCompany);
              }}
              control={<Checkbox  />}
            />
            <TextField
              id="company-name-input"
              label="Organization Name"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              id="company-website-input"
              label="Website"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              id="company-linkedin-input"
              label="Linkedin URL"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              id="company-city-input"
              label="City"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              id="company-state-input"
              label="State"
              variant="outlined"
              onChange={handleChange}
            />
            <TextField
              id="company-country-input"
              label="Country"
              variant="outlined"
              onChange={handleChange}
            />
          </Stack>
        </FormGroup>
      </CardContent>
    </Card>
  )
}


function YesOrNoCard({
  company,
  title,
  inputLabel,
  onYesOrNoChange,
  defaultYes = false,
  AddlOption
}: {
  company: Company;
  title: string;
  inputLabel: string;
  onYesOrNoChange: (matchSelection: string) => void;
  defaultYes?: boolean;
  AddlOption?: React.ReactNode
}) {

  const [isCorrect, setIsCorrect] = React.useState<string | null>(null);
  const handleChange = (event: SelectChangeEvent) => {
    const selection = event.target.value as string;
    setIsCorrect(event.target.value as string);
    onYesOrNoChange(selection.toLocaleLowerCase());
  };

  useEffect(() => {
    setIsCorrect(defaultYes ? 'true' : null);
    if (defaultYes) {
      onYesOrNoChange('true')
    }
  }, [company])

  return (
    <Card>
      <CardHeader
        title={title}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
      <CardContent sx={{ py: 1 }}>
        <FormControl fullWidth>
          <InputLabel >{inputLabel}</InputLabel>
          <Select
            labelId="yes-no-select-label"
            id={inputLabel}
            value={isCorrect}
            label={inputLabel}
            onChange={handleChange}
            disabled={defaultYes}
          >
            <MenuItem value="false">No</MenuItem>
            <MenuItem value="true">Yes</MenuItem>
            {!!AddlOption && AddlOption}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  )
}


function CompanyFeedbackSide({
  companySurvey,
  onSubmit
}: {
  companySurvey: CompanySurveyResponse;
  onSubmit: (resultData: SubmitData) => void
}) {
  const [startTime, setStartTime] = React.useState<number>(now());
  const [company1Type, setCompany1Type] = useState<string>('');
  const [company2Type, setCompany2Type] = useState<string>('');
  const [companyANoLongerExists, setCompanyANoLongerExists] = useState<boolean>(false);
  const [companyBNoLongerExists, setCompanyBNoLongerExists] = useState<boolean>(false);
  const [company1DataMatches, setCompany1DataMatches] = useState<boolean | null>(null);
  const [company1DataComplete, setCompany1DataComplete] = useState<boolean | null>(null);
  const [companiesMatch, setCompaniesMatch] = useState<string | null>(null);
  const [mismatchReasons, setMismatchReasons] = useState<string[]>([]);
  const [correctCompany1, setCorrectCompany1] = useState<ManualCompany>({ ...EMPTY_COMPANY })
  const [correctCompany2, setCorrectCompany2] = useState<ManualCompany>({ ...EMPTY_COMPANY })

  const company1InfoIsWrong = (
    (!company1DataMatches && company1DataMatches !== null)
    || (!company1DataComplete && company1DataComplete !== null)
  );

  const resetCompanyData = () => {
    setCompaniesMatch(null);
    setCompany1Type('');
    setCompany2Type('');
    setCompanyANoLongerExists(false);
    setCompanyBNoLongerExists(false);
    setCompany1DataMatches(null);
    setCompany1DataComplete(null);
    setMismatchReasons([]);
    setCorrectCompany1({ ...EMPTY_COMPANY })
    setCorrectCompany2({ ...EMPTY_COMPANY })
  }

  const getCanSubmit = () => {
    if (companiesMatch) {
      return true;
    }

    if (!companiesMatch && companiesMatch !== null) {
      return true;
    }

    return false;
  }

  const canSubmit = getCanSubmit();

  const isOnlyCompanyName = (company: Company) => {
    if (!!company.name
        && !company.website
        && !company.linkedinUrl
        && !company.hqLocation
        && !company.bio) {
      return true;
    }
    return false
  }

  const handleSubmit = () => {
    const result: SubmitData = {
      companiesMatch: companiesMatch || 'false',
      mismatchReasons,
      companyAType: company1Type,
      companyBType: company2Type,
      companyANoLongerExists: companyANoLongerExists,
      companyBNoLongerExists: companyBNoLongerExists,
      correctCompanyA: correctCompany1,
      correctCompanyB: correctCompany2,
      startTime,
      endTime: now()
    }
    resetCompanyData();
    onSubmit(result);
  }

  useEffect(() => {
    setStartTime(now());
  }, [companySurvey])

  return (
    <Stack direction="column" spacing={2}>
      <YesOrNoCard
        company={companySurvey.companyA}
        title="Does the information for Organization 1 look consistent across provided data points?"
        inputLabel="Org 1 data is correct?"
        onYesOrNoChange={(v) => setCompany1DataMatches(v.startsWith('t'))}
        defaultYes={isOnlyCompanyName(companySurvey.companyA)}
      />
      {(company1DataMatches !== null) && (
        <CompanyNoLongerExistsCard
          company={companySurvey.companyA}
          title={`Organizaiton 1 "${companySurvey.companyA.name}" no longer exists`}
          onChange={setCompanyANoLongerExists}
        />
      )}
      {(company1DataMatches && company1DataMatches !== null) && (
        <YesOrNoCard
          company={companySurvey.companyA}
          title="Is the data for data for Organization 1 complete (as much as is possible)?"
          inputLabel="Is org 1 data is complete"
          onYesOrNoChange={(v) => setCompany1DataComplete(v.startsWith('t'))}
        />
      )}
      {company1InfoIsWrong && (
        <CreateCorrectProfileCard
          company={companySurvey.companyA}
          companyNumber={1}
          onCorrectCompanyChange={setCorrectCompany1}
        />
      )}
      {(company1InfoIsWrong || (company1DataMatches && company1DataComplete)) && (
        <SelectCompanyType
          company={companySurvey.companyA}
          onCompanyTypeChange={(v) => setCompany1Type(v)}
          title={`Select the type for Organization 1 (${companySurvey.companyA.name})`}
        />
      )}
      {!!company1Type && (
        <YesOrNoCard
          company={companySurvey.companyA}
          title="Does Organization 2 match Organization 1?"
          inputLabel="Org 2 matches Org 1?"
          onYesOrNoChange={setCompaniesMatch}
          AddlOption={<MenuItem value="affiliated">They are affiliated</MenuItem>}
        />
      )}
      {companiesMatch !== 'true' && companiesMatch !== null && (
        <>
          <MisMatchReasonCard
            company={companySurvey.companyA}
            includeSubsidiary={companiesMatch === "affiliated"}
            onMismatchReasonChange={setMismatchReasons}
          />
          <CompanyNoLongerExistsCard
            company={companySurvey.companyB}
            title={`Organizaiton 2 "${companySurvey.companyB.name}" no longer exists`}
            onChange={setCompanyBNoLongerExists}
          />
          <CreateCorrectProfileCard
            company={companySurvey.companyB}
            companyNumber={2}
            onCorrectCompanyChange={setCorrectCompany2}
          />
          <SelectCompanyType
            company={companySurvey.companyB}
            onCompanyTypeChange={(v) => setCompany2Type(v)}
            title={`Select the type for Organization 2 (${companySurvey.companyB.name})`}
          />
        </>
      )}
      <Button
        variant="contained"
        disabled={!canSubmit}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Stack>
  )
}

function CompanySurveyCard({
  title,
  company
}: {
  title: string;
  company: Company
}) {
  return (
    <Card sx={{ pb: 2, "&:last-child": { pb: 2 } }}>
      <CardHeader title={title}/>
      <CardContent sx={{ px: 2 }}>
        <Stack spacing={1} direction="column">
          <Stack direction="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Organization name:
            </Typography>
            <Typography>&nbsp;{company.name}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Website:
            </Typography>
            {!!company.website && (
              <>
                &nbsp;
                <ExternalLink to={`https://${company.website}`}>
                  <Typography>{company.website}</Typography>
                </ExternalLink>
              </>
            )}

          </Stack>
          <Stack direction="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Linkedin URL:
            </Typography>
            {!!company.linkedinUrl && (
              <>
                &nbsp;
                <ExternalLink to={`https://${company.linkedinUrl}`}>
                  <Typography>{company.linkedinUrl}</Typography>
                </ExternalLink>
              </>
            )}
          </Stack>
          <Stack direction="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              Bio:
            </Typography>
            {!!company.bio && (
                <>
                &nbsp;
                <Typography>
                  {company.bio}
                </Typography>
                </>
            )}
          </Stack>
          <Stack direction="row">
            <Typography sx={{ fontWeight: 'bold' }}>
              HQ Location:
            </Typography>
            {!!company.hqLocation && (
              <>
              &nbsp;
              <Typography>
                {company.hqLocation}
              </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function CompanyReview({
  companySurvey,
  practice,
  onSubmit
}: {
  companySurvey: CompanySurveyResponse;
  practice: boolean;
  onSubmit: (result: SubmitData) => void;
}) {

  const { apiPersistence } = useAPI();
  const { data } = apiPersistence.useUserSurveyMetrics(
    'useUserSurveyMetrics',
    {input: {surveyType: 'company', practice}}
  );

  const userSurveyMetrics = data?.userSurveyMetrics as UserSurveyMetricsResponse;
  const [surveyCnt, setSurveyCnt] = useState(userSurveyMetrics?.surveyCount || 0);

  const handleSubmit = (result: SubmitData) => {
    setSurveyCnt(surveyCnt + 1);
    onSubmit(result);
  }

  return (
    <>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
          <Alert severity="info">
            Some information may be missing for Organization 1 or 2. If available, click into the
            URLs provided to validate the information. You have completed {surveyCnt} {practice ? 'practice' : ''}  surveys.
          </Alert>
          <CompanySurveyCard title="Organization 1" company={companySurvey.companyA} />
          <CompanySurveyCard title="Organization 2" company={companySurvey.companyB} />
        </Stack>
      </Grid>
      <Divider orientation="vertical" flexItem sx={{ mr: '-9px', ml: 1 }} />
      <Grid item xs={4}>
        <Box
          sx={{
              mb: 2,
              display: "flex",
              flexDirection: "column",
              height: 'calc(100vh - 100px)',
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            <CompanyFeedbackSide companySurvey={companySurvey} onSubmit={handleSubmit} />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
