
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { DefaultLayout } from 'mui/layout';
import {
  AdminPage,
  HomePage,
  LoginPage,
  NotFoundPage,
  ProtectedRoute,
  ProtectedAdminRoute,
  SearchForSurveyPage,
  SearchCompanyPage,
  SearchPersonPage,
  SurveyPage,
} from 'mui/pages';

import { APIProvider, SnackbarProvider } from 'hooks';
import { lightTheme } from './theme';


interface AuthContext {
  user: string | null;
  setUser: (user: string | null) => void;
}

export const AuthContext = React.createContext<AuthContext>({
  user: null,
  setUser: () => {},
});


const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<DefaultLayout />}>
        <Route
          path="admin"
          element={
            <ProtectedAdminRoute>
              <AdminPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="search"
          element={
            <ProtectedAdminRoute>
              <SearchForSurveyPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="search/company"
          element={
            <ProtectedAdminRoute>
              <SearchCompanyPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="search/person"
          element={
            <ProtectedAdminRoute>
              <SearchPersonPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="surveys"
          element={
            <ProtectedRoute>
              <SurveyPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="practice"
          element={
            <ProtectedRoute>
              <SurveyPage practice={true}/>
            </ProtectedRoute>
          }
        />
        <Route index element={
            <ProtectedRoute>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

const root = createRoot(
  document.getElementById('content') || document.createElement('div')
);

const App: React.FC = () => {
  return (
    <>
      <CssBaseline />
      <DocumentTitle title="App">
        <ThemeProvider theme={lightTheme}>
          <SnackbarProvider>
            <APIProvider>
              {/* @ts-ignore */}
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                  <div id="main">
                    <AppRoutes />
                  </div>
                </QueryParamProvider>
              </BrowserRouter>
            </APIProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </DocumentTitle>
    </>
  );
};

root.render(<App />);
