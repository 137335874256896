import * as React from 'react';
import DOMPurify, * as DOMPurifyNS from 'dompurify';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { orange } from '@mui/material/colors';

const StyledMarkBox = styled(Box)(({ theme }) => ({
  [`& mark`]: {
    color: theme.palette.text.secondary,
    background: orange[50],
    fontWeight: 'bold',
  },
}));

export function SanitizedHtml({ html }: { html: string }) {
  return (
    <StyledMarkBox>
      <div
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: (DOMPurify ?? DOMPurifyNS).sanitize(html, {
            ALLOWED_TAGS: ['mark'],
          }),
        }}
      />
    </StyledMarkBox>
  );
}
