import { paginationClasses, Pagination as MuiPagination } from '@mui/material';
import { styled } from '@mui/material/styles';

/**
 * Default MUI pagination component with custom styling applied.
 */
export const Pagination = styled(MuiPagination)(({ theme }) => ({
  padding: theme.spacing(1),
  [`.${paginationClasses.ul}`]: {
    justifyContent: 'end',
  },
}));
